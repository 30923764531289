import '../form-style.css';
import OnBoardCoverImg from '../../../../assets/images/onboarding-cover.png';
import OnBoardQuestionMarkImg from '../../../../assets/images/question-mark-icon.svg';
import GreenTick from '../../../../assets/images/green-tick.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import api from '../../../../config/backend-url';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { emailChecker, passwordChecker } from '../../../shared/helper';
import {
  fetchSignupOtp,
  getCompanyName,
  userRegistration,
} from '../../../services/auth-services';
import { toastr } from 'react-redux-toastr';
import PrivacyPolicy from 'container/shared/privacy-policy';
import TermsCondition from 'container/shared/terms-condition';

/* 
  TODO: INcrease tooltip width
*/

const Signup = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [showV2, setShowV2] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal2, setModal2] = useState(false);

  const handleCloseModal2 = () => setModal2(false);
  const handleShowModal2 = () => setModal2(true);

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [togglePrivacyPolicy, setTogglePrivacyPolicy] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [passwordError, setPasswordError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [otpError, setOtpError] = useState(false);

  const [stepCheck, setStepCheck] = useState(false);

  const validateInput = (key, value) => {
    if (key === 'password') {
      setPasswordError(passwordChecker(value));
    }
    if (key === 'email') {
      setEmailError(emailChecker(value, 'auth'));
    }
  };

  const updateForm = async (key, value) => {
    validateInput(key, value);
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
    if (
      key === 'company_access_key' &&
      !formData.hasOwnProperty('company_name')
    ) {
      await checkCompanyName(value);
    }
  };

  const [companyName, setCompanyName] = useState({ company_name: '' });

  const checkCompanyName = async (value) => {
    try {
      const getName = await getCompanyName(value);
      if (getName) {
        setCompanyName({ company_name: getName.name });
        updateForm('company_name', getName.name);
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const generateOtp = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    console.log({ formData });
    if (
      form.checkValidity() === false ||
      passwordError.length > 0 ||
      emailError.length > 0 ||
      companyName.company_name === ''
    ) {
      setValidated(true);
    } else {
      if (stepCheck) {
        try {
          const getOtp = await fetchSignupOtp(formData.email);
          if (getOtp) {
            handleShow();
            // resendTimer();
            setValidated(false);
          }
        } catch (error) {
          toastr.error(error);
          console.log(error);
        }
      } else {
        event.target.reset();
        setStepCheck(true);
      }
    }
  };

  const register = async () => {
    try {
      const userReg = await userRegistration(formData);
      if (userReg) {
        handleClose();
        handleShowModal2();
      }
    } catch (error) {
      toastr.error(error);
      setOtpError(true);
      console.log(error);
    }
  };

  const redirectToLogin = () => navigate('/login');

  const onShow = (type) => {
    setShowV2((prev) => ({
      ...prev,
      modal1: type === 'modal1' ? true : show.modal1,
      modal2: type === 'modal2' ? true : show.modal2,
      modal3: type === 'modal3' ? true : show.modal3,
    }));
  };

  const onClose = (type) =>
    setShowV2((prev) => ({
      ...prev,
      modal1: type === 'modal1' ? false : show.modal1,
      modal2: type === 'modal2' ? false : show.modal2,
      modal3: type === 'modal3' ? false : show.modal3,
    }));

  const [timer, setTimer] = useState(30);
  useEffect(() => {
    const checkTimer =
      timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(checkTimer);
  }, [timer]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-4 order-md-2">
            <div className="onboardForm">
              <Form
                noValidate
                validated={validated}
                onSubmit={generateOtp}
                className="formSet mt-4"
              >
                <h2 className="mb-3">Sign up</h2>
                {!stepCheck ? (
                  <>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Your full name"
                        onChange={(e) => updateForm('name', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Your designation"
                        onChange={(e) =>
                          updateForm('designation', e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Official Email Id</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Your official email id"
                        onChange={(e) => updateForm('email', e.target.value)}
                        className={
                          submitted && emailError.length > 0
                            ? 'form-control-cstm'
                            : null
                        }
                      />
                      {submitted && emailError.length > 0 ? (
                        <div className="error-toaster">
                          {emailError[0].message}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Company Access Key
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="tooltip-bottom">
                              The Company Access key was sent to the Point of
                              contact for your organisation at the time of
                              company registration.
                            </Tooltip>
                          }
                        >
                          <img
                            src={OnBoardQuestionMarkImg}
                            alt=""
                            className="ml-1"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Access key"
                        onChange={(e) => {
                          updateForm('company_access_key', e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Your company name"
                        value={companyName.company_name || ''}
                        readOnly
                        className={
                          submitted && companyName.company_name === ''
                            ? 'form-control-cstm'
                            : null
                        }
                      />
                      {submitted && companyName.company_name === '' ? (
                        <div className="error-toaster">
                          Company name cannot be empty
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        required
                        placeholder="Your Password"
                        onChange={(e) => updateForm('password', e.target.value)}
                        autoComplete="off"
                        className={
                          submitted && passwordError.length > 0
                            ? 'form-control-cstm'
                            : null
                        }
                      />
                      {submitted && passwordError.length > 0 ? (
                        passwordError.map((res, index) => {
                          return (
                            <div className="error-toaster" key={index}>
                              {res.message}
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        required
                        placeholder="Your Confirm Password"
                        onChange={(e) =>
                          updateForm('confirmPassword', e.target.value)
                        }
                        autoComplete="off"
                        className={
                          submitted &&
                          formData.password &&
                          formData.confirmPassword &&
                          formData.password !== formData.confirmPassword
                            ? 'form-control-cstm'
                            : null
                        }
                      />
                      {submitted &&
                      formData.password &&
                      formData.confirmPassword &&
                      formData.password !== formData.confirmPassword ? (
                        <div className="error-toaster">
                          Please enter password as above
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      style={{ display: 'flex' }}
                      className="mt-2 bodyM"
                    >
                      <Form.Check
                        type="checkbox"
                        id="tandc"
                        name="tandc"
                        required
                      />
                      <Form.Label style={{ margin: 0, marginLeft: 5 }}>
                        I Agree to the{' '}
                        <a
                          href="#"
                          onClick={() => {
                            onShow('modal3');
                            setTogglePrivacyPolicy(false);
                          }}
                        >
                          Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a
                          href="#"
                          onClick={() => {
                            onShow('modal3');
                            setTogglePrivacyPolicy(true);
                          }}
                        >
                          Privacy Policy
                        </a>
                      </Form.Label>
                    </Form.Group>
                  </>
                )}

                <button type="submit" className="bigCTAprimary w-100 mt-4">
                  {!stepCheck ? 'Set Password' : 'Sign Up'}
                </button>
                <p className="mt-5 text-center xsCTA    ">
                  Already have an account? <NavLink to="/login">Log in</NavLink>
                </p>
              </Form>
            </div>
          </div>
          <div className="col-md-7 order-md-1">
            <div className="onboardCover">
              <img src={OnBoardCoverImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Verify OTP Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
        </div>
        <Modal.Body>
          <h3>Verify OTP</h3>
          <form action="" className="formSet">
            <label htmlFor="">
              Please enter the OTP sent to your official email id (Check the
              spam folder if you didn't receive the OTP in your inbox)
            </label>
            <input
              type="text"
              placeholder="OTP"
              onChange={(e) => updateForm('otp', e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {timer === 0 ? (
                <span
                  className="bodyM cursorP"
                  onClick={() => fetchSignupOtp(formData.email)}
                >
                  Resend OTP
                </span>
              ) : (
                <span className="bodyM">
                  Resend OTP in <span className="sucessfull">{timer}s</span>
                </span>
              )}
              {otpError ? <span className="danger">Invalid OTP</span> : <></>}
            </div>
            <button
              type="button"
              className="bigCTAprimary w-100 mt-3"
              onClick={register}
            >
              Verify
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Signup Successfull Modal */}
      <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={handleCloseModal2}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: 'center' }}>
          <img src={GreenTick} alt="" />
          <p className="bodyM mt-2">
            You have successfully signed up. Please wait for the Point of
            Contact of your company to activate your email id, after which you
            will be able to log in.
          </p>
          <button
            type="button"
            className="bigCTAprimary w-100 mt-3"
            onClick={redirectToLogin}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showV2.modal3}
        onHide={() => onClose('modal3')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="cstm"
        scrollable="true"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => onClose('modal3')}
          ></button>
        </div>
        <Modal.Body>
          {togglePrivacyPolicy ? <PrivacyPolicy /> : <TermsCondition />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signup;
