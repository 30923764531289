import YellowPie from '../../../../assets/images/yellowPie.svg';
import BluePie from '../../../../assets/images/bluePie.svg';
import GreenPie from '../../../../assets/images/greenPie.svg';
import PredictorBlueImg from '../../../../assets/images/predictorBlue.svg';
import PredictorYellowImg from '../../../../assets/images/predictorYellow.svg';
import PredictorGreenImg from '../../../../assets/images/predictorGreen.svg';
import VideoRecorder from '../../../../assets/images/video-recorder.svg';
import PieIcon from '../../../../assets/images/pie-icon.svg';
import Low from '../../../../assets/images/low.svg';
import Medium from '../../../../assets/images/medium.svg';
import High from '../../../../assets/images/high.svg';
import InfoICon from '../../../../assets/images/infoIcon.svg';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import ResumePreview from '../../../../assets/images/resume.png';
import { getSignedUrl } from 'container/services/job-services';
import Resume from './Resume';
import api from 'config/backend-url';
import { toastr } from 'react-redux-toastr';

function getSubArrayByPercentage(array, percentage) {
  const length = Math.ceil((percentage / 100) * array.length);
  return array.slice(0, length);
}

const ShortListedVideoIntro = (props) => {
  const dataFetchedRef = useRef(false);

  const [show, setShow] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
  });

  const toggleModal = (state, type) => {
    setShow((prevState) => ({
      ...prevState,
      [state]: type === 'close' ? false : true,
    }));
  };

  const [viewCandidateData, setViewCandidateData] = useState({});
  const [candidate, setCanditate] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedVideoData, setSelectedVideoData] = useState();
  const [viewedCandidateId, setViewedCandidateId] = useState(null);
  const [selectedResume, setSelectedResume] = useState(null);
  const [selectedCandidatesData, setSelectedCandidatesData] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    let cArray = props.candidates.map((item) => {
      const finalScore =
        Math.round(
          Number(item.match_score) * 0.6 +
            Number(item.retain_score) * 0.15 +
            Number(item.personality_score) * 0.1 +
            Number(item.emotion_score) * 0.15
        ) || 0;
      return {
        ...item,
        finalScore,
        isChecked: false,
      };
    });
    cArray?.sort(
      (a, b) =>
        Math.round(b.match_score * 0.8 + b.retain_score * 0.2) -
        Math.round(a.match_score * 0.8 + a.retain_score * 0.2)
    );
    cArray?.sort(
      (a, b) =>
        Math.round(
          Number(b.match_score) * 0.6 +
            Number(b.retain_score) * 0.15 +
            Number(b.personality_score) * 0.1 +
            Number(b.emotion_score) * 0.15
        ) -
        Math.round(
          Number(a.match_score) * 0.6 +
            Number(a.retain_score) * 0.15 +
            Number(a.personality_score) * 0.1 +
            Number(a.emotion_score) * 0.15
        )
    );
    cArray?.sort((a, b) => b.finalScore - a.finalScore);
    setCanditate(cArray);
    props.trait?.sort((a, b) => a.trait.order_id - b.trait.order_id);
  }, [props.candidates]);

  const handleCheckBox = (candidateData, isChecked) => {
    if (isChecked) {
      setSelectedCandidatesData((prevState) => ({
        ...prevState,
        [candidateData.id]: candidateData,
      }));
    } else {
      setSelectedCandidatesData((prevState) => {
        const { [candidateData.id]: _, ...rest } = prevState;
        return rest;
      });
    }
  };
  const handleCheckBoxByPercentage = (isChecked, percentage) => {
    if (isChecked) {
      const temp = {};
      const subCandidates = getSubArrayByPercentage(candidate, percentage);
      subCandidates.forEach((item) => {
        temp[item.id] = item;
      });
      setSelectedCandidatesData(temp);
    } else {
      setSelectedCandidatesData({});
    }
  };

  const handleAllCheckBox = (isChecked) => {
    if (isChecked) {
      const temp = {};
      candidate.forEach((item) => {
        temp[item.id] = item;
      });
      setSelectedCandidatesData(temp);
    } else {
      setSelectedCandidatesData({});
    }
  };

  const handleDownloadFinalShortList = async () => {
    try {
      setIsDownloading(true);
      const res = await api.post('/candidate/send_resume_email', {
        canddidate_ids: Object.keys(selectedCandidatesData),
        job_id: props.job_id,
      });
      console.log('res', res);
      toggleModal('modal2', 'open');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  const fetchSignedUrl = async (bucket_key) => {
    setIsGenerating(true);
    const response = await getSignedUrl(bucket_key);
    if (response.success) {
      setSelectedVideoData({
        signedUrl: response.singedUrl,
      });
      setIsGenerating(false);
      return;
    }
    setIsGenerating(false);

    // Show error
  };

  useEffect(() => {
    if (Object.keys(viewCandidateData).length) {
      fetchSignedUrl(viewCandidateData.uploads.INTRO_VIDEO.bucket_key);
    }
  }, [viewCandidateData]);

  return (
    <div>
      <div className="stickyTop mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="tableControl">
              <div className="tableConLeft">
                <div className="rowSelectors">
                  <p className="smallBold">Quick Select</p>
                  <div id="resumeFilter">
                    <p className="smallBold">Top</p>
                    <button
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(true, 10);
                      }}
                    >
                      10%
                    </button>
                    <button
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(true, 20);
                      }}
                    >
                      20%
                    </button>
                    <button
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(true, 30);
                      }}
                    >
                      30%
                    </button>
                    <button
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(true, 40);
                      }}
                    >
                      40%
                    </button>
                    <button
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(true, 50);
                      }}
                    >
                      50%
                    </button>
                    <button
                      className={
                        'clear ' +
                        (props.status === 'CLOSED' ||
                        props.status === 'ARCHIVED'
                          ? 'disables'
                          : '')
                      }
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      onClick={() => {
                        handleCheckBoxByPercentage(false, 0);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="tableSelectProgress">
                  <p className="smallBold mb-0">Selected</p>
                  <div
                    className="progressWrap"
                    style={{
                      width: '250px',
                    }}
                  >
                    <span className="smallBold">0</span>
                    <div className="w-100">
                      <div
                        className="progress cstmProgessResume"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={
                          Object.keys(selectedCandidatesData).length
                        }
                        aria-valuemin="0"
                        aria-valuemax={candidate.length}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width:
                              (Object.keys(selectedCandidatesData).length /
                                candidate.length) *
                                100 +
                              '%',
                          }}
                        ></div>
                      </div>
                      <div
                        className="progress-bar-tooltip"
                        style={{
                          paddingLeft:
                            (Object.keys(selectedCandidatesData).length /
                              candidate.length) *
                              100 +
                            '%',
                          visibility:
                            Object.keys(selectedCandidatesData).length > 0
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {Object.keys(selectedCandidatesData).length}
                      </div>
                    </div>
                    <span className="smallBold">100%</span>
                  </div>
                </div>
              </div>
              <div className="tableConRight">
                <button
                  className={
                    !Object.keys(selectedCandidatesData).length
                      ? 'disables smallCTAprimary'
                      : 'smallCTAprimary cursorP'
                  }
                  onClick={handleDownloadFinalShortList}
                  disabled={
                    !Object.keys(selectedCandidatesData).length || isDownloading
                  }
                >
                  {isDownloading
                    ? 'Downloading...'
                    : 'Download Final Shortlist'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table className="table resumeTable mt-3">
        <thead>
          <tr>
            <th scope="col">
              <input
                type="checkbox"
                checked={
                  candidate.length > 0 &&
                  Object.keys(selectedCandidatesData).length ===
                    candidate.length
                }
                onChange={(e) => handleAllCheckBox(e.target.checked)}
                disabled={
                  props.status === 'CLOSED' || props.status === 'ARCHIVED'
                }
                className={
                  props.status === 'CLOSED' || props.status === 'ARCHIVED'
                    ? 'disables'
                    : ''
                }
              />
            </th>
            <th scope="col">Rank</th>
            <th scope="col">Candidate Name</th>
            <th scope="col">
              Resume
              <br />
              Match
            </th>
            <th scope="col">
              Retention
              <br />
              Predictor
            </th>
            <th scope="col">
              Apparent
              <br />
              Personality Fit{' '}
              <a href="#" onClick={() => toggleModal('modal1', 'open')}>
                <img src={InfoICon} alt="" />
              </a>
            </th>
            <th scope="col">
              Apparent
              <br />
              Emotional Strength{' '}
              <a href="#" onClick={() => toggleModal('modal3', 'open')}>
                <img src={InfoICon} alt="" />
              </a>
            </th>
            <th scope="col">
              Final
              <br />
              Score
            </th>
            <th scope="col">Video</th>
          </tr>
        </thead>

        <tbody style={{ whiteSpace: 'nowrap' }}>
          {candidate.length > 0 &&
            candidate.map((item, index) => {
              const traits = !item.video_score?.openness_score
                ? []
                : [
                    {
                      name: 'Openness',
                      score: (+item.video_score?.openness_score || 0) * 100,
                      keywords: item.video_score?.openness_keywords,
                      range_name:
                        props.job.traits.find(
                          (trait) => trait.trait.name === 'Openness'
                        )?.trait_range?.name || '',
                    },
                    {
                      name: 'Conscientiousness',
                      score:
                        (+item.video_score?.conscientiousness_score || 0) * 100,
                      keywords: item.video_score?.conscientiousness_keywords,
                      range_name:
                        props.job.traits.find(
                          (trait) => trait.trait.name === 'Conscientiousness'
                        )?.trait_range?.name || '',
                    },
                    {
                      name: 'Extraversion',
                      score: (+item.video_score?.extraversion_score || 0) * 100,
                      keywords: item.video_score?.extraversion_keywords,
                      range_name:
                        props.job.traits.find(
                          (trait) => trait.trait.name === 'Extraversion'
                        )?.trait_range?.name || '',
                    },
                    {
                      name: 'Agreeableness',
                      score:
                        (+item.video_score?.agreeableness_score || 0) * 100,
                      keywords: item.video_score?.agreeableness_keywords,
                      range_name:
                        props.job.traits.find(
                          (trait) => trait.trait.name === 'Agreeableness'
                        )?.trait_range?.name || '',
                    },
                    {
                      name: 'Neuroticism',
                      score: (+item.video_score?.neuroticism_score || 0) * 100,
                      keywords: item.video_score?.neuroticism__keywords || '',
                      range_name:
                        props.job.traits.find(
                          (trait) => trait.trait.name === 'Neuroticism'
                        )?.trait_range?.name || '',
                    },
                  ];
              item.isChecked = item.id in selectedCandidatesData;
              console.log('item.video_score', props.job.traits, traits);
              return (
                <tr key={item.resume_id}>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={item.id in selectedCandidatesData}
                      onChange={(e) => handleCheckBox(item, e.target.checked)}
                      disabled={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      }
                      className={
                        props.status === 'CLOSED' || props.status === 'ARCHIVED'
                          ? 'disables'
                          : ''
                      }
                    />
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <span className="rank">{index + 1}</span>
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <span
                      className={`actives smallSubT cursorP ${
                        item.id === viewedCandidateId
                          ? 'fw-bolder text-decoration-underline'
                          : ''
                      }`}
                      onClick={() => {
                        setViewedCandidateId(item.id);
                        setSelectedResume(item.uploads?.RESUME);
                        toggleModal('modal5', 'open');
                      }}
                    >
                      {item.name || item.name_extracted_from_resume}
                    </span>
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <b>{Math.round(Number(item.match_score)) + '%' || 0}</b>
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <img
                      src={
                        item.retain_score >= 80
                          ? PredictorGreenImg
                          : item.retain_score >= 50 && item.retain_score < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={
                        item.personality_score >= 80
                          ? PredictorGreenImg
                          : item.personality_score >= 50 &&
                            item.personality_score < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                    <span
                      className="ml-3"
                      type="button"
                      id="personalityFit"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setViewedCandidateId(item.id);
                      }}
                    >
                      <img className="ml-1" src={PieIcon} alt="" />
                    </span>
                    <div
                      className="dropdown-menu dropdown-menu-end personalities"
                      aria-labelledby="personalityFit"
                    >
                      <div className="row">
                        {traits.length > 0 &&
                          traits.map((item, index) => {
                            return (
                              <div className="col-12 col-md">
                                <div className="personalityPie">
                                  <img
                                    src={
                                      item.score >= 55
                                        ? GreenPie
                                        : item.score >= 35 && item.score <= 54
                                        ? BluePie
                                        : YellowPie
                                    }
                                    alt=""
                                  />
                                  <div
                                    className={
                                      item.range_name === 'Low'
                                        ? 'yellowCheck'
                                        : item.range_name === 'Medium'
                                        ? 'blueCheck'
                                        : 'greenCheck'
                                    }
                                    style={{
                                      left:
                                        item.range_name === 'Low'
                                          ? '15px'
                                          : item.range_name === 'Medium'
                                          ? '60px'
                                          : item.trait_range?.anme === 'High'
                                          ? '110px'
                                          : null,
                                      top:
                                        item.range_name === 'Low'
                                          ? '35px'
                                          : item.range_name === 'Medium'
                                          ? '10px'
                                          : item.range_name === 'High'
                                          ? '35px'
                                          : null,
                                    }}
                                  ></div>
                                  <p style={{ textAlign: 'center' }}>
                                    {item.name}
                                    <br />
                                    <span className="text-wrap">
                                      {item.keywords}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="legends">
                        Desired Personality Traits
                        <span>
                          <img src={Low} alt="" /> Low
                        </span>
                        <span>
                          <img src={Medium} alt="" /> Medium
                        </span>
                        <span>
                          <img src={High} alt="" /> High
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <img
                      src={
                        item.emotion_score >= 80
                          ? PredictorGreenImg
                          : item.emotion_score >= 50 && item.emotion_score < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    <b>{item.finalScore > 0 ? `${item.finalScore}%` : ''}</b>
                  </td>
                  <td
                    style={{
                      backgroundColor: item.isChecked && 'rgb(240 245 255)',
                    }}
                  >
                    {!!item?.uploads?.INTRO_VIDEO?.bucket_key && (
                      <span
                        className="cursorP"
                        onClick={() => {
                          toggleModal('modal4', 'open');
                          setViewCandidateData((prevState) => ({
                            ...prevState,
                            ...item,
                          }));
                        }}
                      >
                        <img src={VideoRecorder} alt="" />
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          {candidate.length === 0 && (
            <tr>
              <td colSpan={9}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal Resume Sample */}
      <Modal
        show={show.modal5}
        onHide={() => {
          setSelectedResume(null);
          toggleModal('modal5', 'close');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="resume-page"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal5', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <Resume resumeObj={selectedResume} />
        </Modal.Body>
      </Modal>

      {/* Modal Apparent Personality Fit */}
      <Modal
        show={show.modal1}
        onHide={() => toggleModal('modal1', 'close')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal1', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-left">
          <p>
            <strong>Disclaimer:</strong> This is not a replacement for rigorous
            psychometric analysis. However, researches have shown there is a
            strong correlation between personality traits and apparent
            video-based analytics. This metric should be used as a broad
            guidance for comparative purpose only.
          </p>
        </Modal.Body>
      </Modal>

      {/* Modal Apparent Personality Fit */}

      <Modal
        show={show.modal3}
        onHide={() => toggleModal('modal3', 'close')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal3', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-left">
          <p>
            <strong>Disclaimer:</strong> This is not a replacement for rigorous
            psychometric analysis. However, today's AI algorithms can detect
            positive, neutral and negative emotions from video footages. This
            metric calculates the percentage of frames that shows positive and
            neutral emotions as a percentage of total frames analyzed. This
            metric should be used as a broad guidance for comparative purpose
            only.
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal2}
        onHide={() => toggleModal('modal2', 'close')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal2', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>Thank You. The Final Shortlist has been sent to your email.</p>
          <Button
            onClick={() => toggleModal('modal2', 'close')}
            style={{ width: '100px' }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>

      {/* Modal Video Play */}
      <Modal
        show={show.modal4}
        onHide={() => toggleModal('modal4', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        dialogClassName="w-full"
      >
        <div className="w-100 btn-close-flex pt-0">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal4', 'close')}
            style={{ zIndex: 9 }}
          ></button>
        </div>
        <Modal.Body className="p-1">
          <div className="selectCadidate mb-2" style={{ marginTop: '-20px' }}>
            <form>
              <input
                type="checkbox"
                checked={
                  viewCandidateData.candidate_id in selectedCandidatesData
                }
                onChange={(e) => {
                  handleCheckBox(viewCandidateData, e.target.checked);
                }}
                disabled={
                  props.status === 'CLOSED' || props.status === 'ARCHIVED'
                }
                className={
                  'cursorP ' +
                  (props.status === 'CLOSED' || props.status === 'ARCHIVED'
                    ? 'disables'
                    : '')
                }
              />
              <label htmlFor="selectCadite" className="actives ml-1">
                Select this Candidate
              </label>
            </form>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="cadidateInfo">
                Candidate name
                <p>
                  {(viewCandidateData.name === 'Not Present'
                    ? 'Not Detected'
                    : viewCandidateData.name) ||
                    viewCandidateData.name_extracted_from_resume}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cadidateInfo">
                {props.job.client_name
                  ? `${props.job.client_name} (${props.job.industry_name})`
                  : props.job.department}
                <p>{props.job.title}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`cadidateInfo ${props.job.location ? '' : 'd-none'}`}
              >
                Location
                <p>{props.job.location}</p>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2">
              <div className="cadidateInfo">
                <p>{Math.round(Number(viewCandidateData.match_score))}%</p>
                Resume
                <br />
                Match
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    <img
                      src={
                        Number(viewCandidateData.retain_score) >= 80
                          ? PredictorGreenImg
                          : Number(viewCandidateData.retain_score) >= 50 &&
                            Number(viewCandidateData.retain_score) < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                    <br />
                    Retention
                    <br />
                    Predictor
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    <img
                      src={
                        Number(
                          viewCandidateData?.video_score?.personality_score
                        ) >= 80
                          ? PredictorGreenImg
                          : Number(
                              viewCandidateData?.video_score?.personality_score
                            ) >= 50 &&
                            Number(
                              viewCandidateData?.video_score?.personality_score
                            ) < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                    <br />
                    Apparent
                    <br />
                    Personality Fit
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    <img
                      src={
                        Number(viewCandidateData?.video_score?.emotion_score) >=
                        80
                          ? PredictorGreenImg
                          : Number(
                              viewCandidateData?.video_score?.emotion_score
                            ) >= 50 &&
                            Number(
                              viewCandidateData?.video_score?.emotion_score
                            ) < 80
                          ? PredictorBlueImg
                          : PredictorYellowImg
                      }
                      alt=""
                    />
                    <br />
                    Apparent Emotional
                    <br />
                    Strength
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="cadidateInfo text-center">
                <p>
                  {Math.round(
                    Number(viewCandidateData.match_score) * 0.6 +
                      Number(viewCandidateData.retain_score) * 0.15 +
                      Number(viewCandidateData.personality_score) * 0.1 +
                      Number(viewCandidateData.emotion_score) * 0.15
                  ) || 0}
                  %
                </p>
                Final
                <br />
                Score
              </div>
            </div>
          </div>
          {isGenerating ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <video
              controls
              className="w-100 mt-4"
              style={{ borderRadius: '16px', marginBottom: '-8px' }}
            >
              <source src={selectedVideoData?.signedUrl} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShortListedVideoIntro;
