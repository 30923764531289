const PrivacyPolicy = () => {
  return (
    <div className="p-3 pt-1">
      <h2 className="mb-4">Privacy Terms</h2>
      <h6 className="mb-4">
        This Privacy Terms ("Privacy Policy") is made and entered into by and
        between Fairee Technologies Pte Ltd ("Provider"), and the entity or
        individual ("Customer") agreeing to the terms and conditions herein.
      </h6>
      <ol>
        <li className="mb-3">
          Collection of Information: Provider collects personal information from
          Customer, including but not limited to name, email address, phone
          number, and billing information. Provider also collects non-personal
          information such as IP address, browser type, and operating system.
        </li>
        <li className="mb-3">
          Use of Information: Provider uses the information collected from
          Customer to provide and improve the Service, to communicate with
          Customer, and to respond to inquiries and support requests
        </li>
        <li className="mb-3">
          {' '}
          Sharing of Information: Provider does not sell, trade, or rent
          Customer's personal information to third parties. Provider may share
          Customer's information with its service providers and partners to
          provide and improve the Service. Provider may also share information
          as required by law or to protect its rights or property.
        </li>
        <li className="mb-3">
          Security: Provider takes reasonable measures to protect the security
          of Customer's information, including using industry-standard
          encryption and security protocols. However, no security system is 100%
          secure, and Provider cannot guarantee the security of Customer's
          information.
        </li>
        <li className="mb-3">
          Marketing: Provider may use Customer's information to send marketing
          communications about the Service. Customer may opt-out of receiving
          such communications by following the instructions in the communication
        </li>
        <li className="mb-3">
          Cookies and Tracking: Provider uses cookies and other tracking
          technologies to collect non-personal information about Customer's use
          of the Service. Customer may disable cookies in their browser, but
          this may limit their use of certain features of the Service.
        </li>
        <li className="mb-3">
          Data Retention: Provider retains Customer's information for as long as
          necessary to provide the Service and for any legal or regulatory
          requirements.
        </li>
        <li className="mb-3">
          Access and Correction: Customer may access and update their personal
          information by logging into their account. Customer may also request
          that Provider correct any inaccuracies in their information
        </li>
        <li className="mb-3">
          Children's Privacy: Provider does not knowingly collect personal
          information from children under the age of 13. If Provider becomes
          aware that it has collected personal information from a child under
          the age of 13, it will delete such information.
        </li>
        <li className="mb-3">
          Changes to Policy: Provider may update this Privacy Policy from time
          to time by posting the updated version on its website. Customer's
          continued use of the Service after such notice constitutes acceptance
          of the updated terms.
        </li>
        <li className="mb-3">
          Contact: If Customer has any questions or concerns about this Privacy
          Policy, they may contact Provider at support@fairee.com.sg{' '}
        </li>
      </ol>
      <h6 className="my-4">
        IN WITNESS WHEREOF, the parties have executed this Privacy Policy as of
        the date of registration for the Service.
      </h6>
      <p>
        <b>Fairee Technologies Pte Ltd</b> <br></br>
        By: Debashis Tarafdar <br></br>
        Title: Founder and Chief Executive Officer <br></br>
        31 May 2023
      </p>
    </div>
  );
};

export default PrivacyPolicy;
<br></br>;
