import { connect } from "react-redux";
import CommonHeader from "../components/navbar/common-header";
import Header from "../components/navbar/header";
import Sidebar from "../components/navbar/sidebar";
import "./layout.css";
import { useLocation, useParams } from "react-router-dom";

const Layout = (props) => {
  const location = useLocation();
  const restrictedUrl =
    location.pathname.match("/video_upload") === null ? false : true;
  console.log("location", location);
  const isOpenRoute =
    location.pathname === "/" ||
    location.pathname === "/about" ||
    location.pathname === "/interested" ||
    location.pathname.includes("/set-password") ||
    location.pathname === "/contact-us";
  if (isOpenRoute) {
    return <>{props.children}</>;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row m-0">
          <aside
            className={
              props.accounts.isLoggedIn && !restrictedUrl
                ? "col-2 px-0 fixed-top sideNav"
                : null
            }
          >
            {props.accounts.isLoggedIn && !restrictedUrl ? (
              <Sidebar></Sidebar>
            ) : (
              <Header></Header>
            )}
          </aside>
          <main className="col-10 invisible"></main>
          <main
            className={
              props.accounts.isLoggedIn && !restrictedUrl
                ? "col offset-lg-2 px-0-important"
                : "mt-5"
            }
          >
            {props.accounts.isLoggedIn && !restrictedUrl ? (
              <CommonHeader></CommonHeader>
            ) : null}
            {props.children}
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {},
  };
};

export default connect(mapStateToProps, "")(Layout);
