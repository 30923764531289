import { useQueryClient } from '@tanstack/react-query';
import { useProfileUpdate } from 'hooks/useProfileUpdate';
import React, { useState } from 'react';

function getInitials(name) {
  return name
    .split(' ')
    .map((part) => part[0].toUpperCase())
    .join('');
}

const UserManagement = ({ userManagementData }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  const queryClient = useQueryClient();
  const updateUserManageMent = useProfileUpdate(queryClient);

  const handleIds = (isChecked, id, status) => {
    if (isChecked) {
      setSelectedIds([...selectedIds, { id, status }]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId.id !== id));
    }
  };

  const handleDeactivate = async () => {
    await updateUserManageMent.mutateAsync({
      user_management: selectedIds.map((selectedId) => ({
        id: selectedId.id,
        status: selectedId.status === 'active' ? 'deactive' : 'active',
      })),
    });
    setSelectedIds([]);
  };

  if (!userManagementData?.length) return null;

  let filteredData = userManagementData;
  if (searchText) {
    filteredData = userManagementData.filter(({ name }) =>
      name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  return (
    <div
      className="tab-pane fade mt-5"
      id="nav-management"
      role="tabpanel"
      aria-labelledby="nav-management-tab"
      tabindex="0"
    >
      <div className="stickyTop">
        <div className="row">
          <div className="col-md-5 cardControls">
            <h5>
              {
                userManagementData.filter((data) => data.status === 'active')
                  .length
              }{' '}
              Active Users
            </h5>
          </div>
          <div className="col-md-7 cardControls">
            <div className="controlls">
              <button
                disabled={!selectedIds.length || updateUserManageMent.isPending}
                className="smallCTAsecondary"
                onClick={handleDeactivate}
              >
                {updateUserManageMent.isPending
                  ? 'Working...'
                  : 'Activate / Deactivate'}
              </button>
              <div className="dropdown d-none">
                User:{' '}
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  All Users
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 2
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 3
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <form action="" className="formSet">
                  <input
                    type="search"
                    placeholder="Search"
                    value={searchText}
                    onChange={({ target: { value } }) => setSearchText(value)}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {filteredData.map((data) => (
          <div key={data.id} className="col-md-3">
            <div
              className={`userMcard ${
                data.status !== 'active' ? 'disables' : ''
              }`}
            >
              <div className="columnRow">
                <input
                  type="checkbox"
                  onChange={({ target: { checked } }) => {
                    handleIds(checked, data.id, data.status);
                  }}
                  checked={
                    !!selectedIds.find(
                      (selectedId) => selectedId.id === data.id
                    )
                  }
                />
              </div>
              <div className="nameicon">
                <span className="nameThum">{getInitials(data.name)}</span>
                <h6>{data.name}</h6>
                <p>{data.designation}</p>
              </div>
              <p>{data.email} </p>

              <div className="columnRow">
                <div>Open Job Positions</div>
                <span className="subTitle">
                  {data.total_open_jobs_count || 0}
                </span>
              </div>
              <div className="columnRow d-none">
                <div>Total Credits Used</div>
                <span className="subTitle">120</span>
              </div>
              <div className="columnRow">
                <div>Total Job Positions</div>
                <span className="subTitle">{data.total_jobs_count || 0}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserManagement;
