import { useEffect, useRef, useState } from "react";
import JobPositionList from "./job-position-list";
import { fetchAllJobs } from "../../../services/job-services";

const JobDashboard = () => {
    const dataFetchedRef = useRef(false);
    const [tabs, setTabs] = useState({ open: true, closed: false, deleted: false });

    const updateTabs = (key, value) => {
        setTabs(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const [jobsList, setJobsList] = useState([]);

    const getJobsList = async () => {
        const jobs = await fetchAllJobs();
        setJobsList([...jobs.data])
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getJobsList()
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="tabs">
                        <span className={tabs.open && 'active'} onClick={() => { updateTabs('open', true); updateTabs('closed', false); updateTabs('deleted', false) }}>Open Job positions</span>
                        <span className={tabs.closed && 'active'} onClick={() => { updateTabs('open', false); updateTabs('closed', true); updateTabs('deleted', false) }}>Closed Job positions</span>
                        <span className={tabs.deleted && 'active'} onClick={() => { updateTabs('open', false); updateTabs('closed', false); updateTabs('deleted', true) }}>Deleted Job positions</span>
                    </div>
                </div>
            </div>
            <JobPositionList jobs={jobsList} status={tabs}></JobPositionList>
        </div>
    )
}

export default JobDashboard;