import * as actions from './action-type';

const initialState = {
    account: {
        isLoggedIn: false,
        name: '',
        company_id: '',
        email: '',
        designation: '',
        login_id: '',
        role_id: '',
        id: '',
        pageHeading: 'Dashboard',
        company: {
            name: '',
            phone_number: '',
            email: ''
        }

    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.ON_LOGIN:
            return {
                ...state,
                account: {
                    ...state.account,
                    isLoggedIn: action.payload.data.isLoggedIn,
                    name: action.payload.data.name,
                    company_id: action.payload.data.company_id,
                    email: action.payload.data.login.email,
                    designation: action.payload.data.designation,
                    login_id: action.payload.data.login_id,
                    role_id: action.payload.data.role_id,
                    id: action.payload.data.id,
                    company: {
                        ...state.account.company,
                        name: action.payload.data.company.name,
                        phone_number: action.payload.data.company.phone_number,
                        email: action.payload.data.company.email,
                    }
                }
            }
        case actions.ON_LOGGOUT:
            return {
                ...state,
                account: {
                    ...state.account,
                    isLoggedIn: false,
                    name: '',
                    company_id: '',
                    email: '',
                    designation: '',
                    login_id: '',
                    role_id: '',
                    id: '',
                    pageHeading: 'Dashboard',
                    company: {
                        ...state.account.company,
                        name: '',
                        phone_number: '',
                        email: '',
                    }
                }
            }
        case actions.ON_NAVIGATE:
            return {
                ...state,
                account: {
                    ...state.account,
                    pageHeading: action.payload.data.pageHeading,
                    subHeading: action.payload.data.subHeading || ''
                }
            }
        default:
            return state
    }
}