import { useQuery } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import dayjs from 'dayjs';

import { getDashboardData } from 'container/services/user-services';
import Stats from './Stats';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { getSignedUrl } from 'container/services/job-services';
import { onNavigate } from 'container/store/action';
import { useDispatch } from 'react-redux';

function calculateUploadPercentage(uploaded, total) {
  if (total === 0) {
    return 0;
  }
  return (uploaded / total) * 100;
}

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedVideoData, setSelectedVideoData] = useState();

  const dispatch = useDispatch();

  const { isLoading, data } = useQuery({
    queryKey: ['dashboard'],
    queryFn: getDashboardData,
    onSuccess: (data) => {
      console.log('success data', data);
    },
    onError: (error) => {
      console.log('error data', error.message);
    },
  });

  const handleVideoCardClick = async (videoData) => {
    console.log(videoData);
    setIsGenerating(true);
    const response = await getSignedUrl(videoData.bucket_key);
    if (response.success) {
      setSelectedVideoData({
        ...videoData,
        signedUrl: response.singedUrl,
      });
    } else {
      // TODO: show error message

      setShow(false);
    }
    setIsGenerating(false);
  };

  if (isLoading) {
    return (
      <div className="container-fluid d-flex justify-content-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const dashboardData = data?.data?.data;

  if (!dashboardData) {
    return (
      <div className="container-fluid d-flex justify-content-center mt-5">
        No Data found
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid px-0-important">
        <div className="row flex-nowrap">
          <div className="col py-3">
            <div className="container-fluid mt-4">
              <Stats
                totalNumberOfResumes={dashboardData.total_number_of_resumes}
                totalOpenPosition={dashboardData.total_open_position}
                totalVideoSubmission={dashboardData.total_video_submission}
              />

              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="dashCards mVgap">
                    <div className="cardHeads mb-2">
                      <h6>Recently Added Positions</h6>
                      <Link
                        to="/job-dashboard"
                        className="tertiaryBtn"
                        onClick={() =>
                          dispatch(onNavigate({ pageHeading: 'Job Positions' }))
                        }
                      >
                        View all
                      </Link>
                    </div>
                    <div>
                      {dashboardData.recently_added_position?.map(
                        (position) => (
                          <Link
                            to={`/job-details/${position}`}
                            className="cardItem"
                            key={position}
                            onClick={() =>
                              dispatch(
                                onNavigate({
                                  pageHeading:
                                    dashboardData.jobs[position].title,
                                  subHeading: dashboardData.jobs[position]
                                    .client_name
                                    ? `${dashboardData.jobs[position].client_name} (${dashboardData.jobs[position].industry_name})`
                                    : dashboardData.jobs[position].department,
                                })
                              )
                            }
                          >
                            <div>
                              {dashboardData.jobs[position].client_name
                                ? `${
                                    dashboardData.jobs[position].client_name
                                  } (${
                                    dashboardData.jobs[position]
                                      .industry_name || ''
                                  })`
                                : dashboardData.jobs[position].department}{' '}
                              <p>{dashboardData.jobs[position].title}</p>
                            </div>
                            <img src="images/chevron-right.svg" alt="" />
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dashCards mVgap">
                    <div className="cardHeads mb-2">
                      <h6>Invitation Responses</h6>
                    </div>
                    <div>
                      {dashboardData.invitition_responses?.map((invitation) => {
                        const totalNeedsToBeUploaded =
                          invitation.uploaded + invitation.not_uploaded;

                        let percentage = calculateUploadPercentage(
                          invitation.uploaded,
                          totalNeedsToBeUploaded
                        );

                        return (
                          <Link
                            to={`/job-details/${invitation.job_id}?tab=invitations`}
                            className="cardItem"
                            key={`invitation-${invitation.job_id}`}
                            onClick={() =>
                              dispatch(
                                onNavigate({
                                  pageHeading:
                                    dashboardData.jobs[invitation.job_id].title,
                                  subHeading: dashboardData.jobs[
                                    invitation.job_id
                                  ].client_name
                                    ? `${
                                        dashboardData.jobs[invitation.job_id]
                                          .client_name
                                      } (${
                                        dashboardData.jobs[invitation.job_id]
                                          .industry_name
                                      })`
                                    : dashboardData.jobs[invitation.job_id]
                                        .department,
                                })
                              )
                            }
                          >
                            <div className="w-100">
                              {dashboardData.jobs[invitation.job_id].client_name
                                ? `${
                                    dashboardData.jobs[invitation.job_id]
                                      .client_name
                                  } (${
                                    dashboardData.jobs[invitation.job_id]
                                      .industry_name
                                  })`
                                : dashboardData.jobs[invitation.job_id]
                                    .department}{' '}
                              <p>
                                {dashboardData.jobs[invitation.job_id].title}
                              </p>
                              <div
                                className="progress"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow={percentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: `${percentage}%` }}
                                ></div>
                              </div>
                            </div>
                            <img src="images/chevron-right.svg" alt="" />
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dashCards mVgap">
                    <div className="cardHeads mb-2">
                      <h6>Recent Video Submissions</h6>
                    </div>
                    {dashboardData?.recent_video_submission?.map((video) => (
                      <div
                        className="videoCards"
                        onClick={() => {
                          setShow(true);
                          handleVideoCardClick(video);
                        }}
                        key={video.candidate_id}
                      >
                        <div className="videoDashCover">
                          <div className="videoinfo">
                            <img src="images/play_icon.svg" alt="" />{' '}
                            {video.name}
                          </div>
                          <div className="videoDate">
                            {dayjs(video.updatedAt).format(
                              'DD MMM YYYY hh:mm A'
                            )}
                          </div>
                        </div>
                        <div className="dipartment">
                          {dashboardData.jobs[video.job_id].client_name
                            ? `${
                                dashboardData.jobs[video.job_id].client_name
                              } (${
                                dashboardData.jobs[video.job_id].industry_name
                              })`
                            : dashboardData.jobs[video.job_id].department}{' '}
                        </div>
                        <p>{dashboardData?.jobs[video.job_id]?.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShow(false)}
        dialogClassName="w-full"
      >
        <Modal.Body>
          {isGenerating ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    Candidate name
                    <p>{selectedVideoData?.name || ''}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    {dashboardData.jobs[selectedVideoData?.job_id]
                      ?.client_name ||
                      dashboardData.jobs[selectedVideoData?.job_id]?.department}
                    <p>
                      {dashboardData?.jobs[selectedVideoData?.job_id]?.title}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cadidateInfo">
                    Location
                    <p>
                      {dashboardData?.jobs[selectedVideoData?.job_id]?.location}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3 d-none">
                <div className="col-md-2">
                  <div className="cadidateInfo">
                    <p>92%</p>
                    Resume
                    <br />
                    Match
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="cadidateInfo">
                        <img src="images/blueTag.svg" alt="" />
                        <br />
                        Retention
                        <br />
                        Predictor
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="cadidateInfo">
                        <img src="images/greenTag.svg" alt="" />
                        <br />
                        Apparent
                        <br />
                        Personality Fit
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="cadidateInfo">
                        <img src="images/blueTag.svg" alt="" />
                        <br />
                        ApparentEmotional
                        <br />
                        Strength
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="cadidateInfo">
                    <p>88%</p>
                    Final
                    <br />
                    Score
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <video controls>
                  <source src={selectedVideoData?.signedUrl} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
