import api from "../../config/backend-url";
import { extensionToMimeTypeMap } from "./constant";

export const fetchTraits = () => {
  return new Promise((resolve, reject) => {
    api
      .post("trait/get_all")
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAllowedFileTypes = () => {
  return new Promise((resolve, reject) => {
    api
      .post("job/get_supported_file_types")
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addJobs = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("job/create", data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAllJobs = () => {
  return new Promise((resolve, reject) => {
    api
      .post("job/get_by_user")
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchJobByID = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("job/get_by_id", data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadResumeAPI = (data, config = {}) => {
  return new Promise((resolve, reject) => {
    api
      .post("job/upload_resumes", data, config)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateJobInfo = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("job/update", data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const permanentDelete = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("job/delete", data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSignedUrl = async (bucketKey) => {
  try {
    const { data: response } = await api.post("get_signed_url", {
      key: bucketKey,
    });
    if (response.response_code === 1) {
      return {
        success: true,
        singedUrl: response.data,
      };
    }
    return {
      success: false,
      message: response.message,
    };
  } catch (error) {
    console.log("myLog getSignedUrl error", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

export const getBlobData = async (signedUrl, extType = "pdf") => {
  try {
    const response = await fetch(signedUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("extType", extType);
    const blob = await response.blob();
    const pdfBlob = new Blob([blob], { type: extensionToMimeTypeMap[extType] });
    return pdfBlob;
  } catch (error) {
    console.error(
      "myLog There has been a problem with your fetch operation: ",
      error
    );
  }
};

export const getTextData = async (signedUrl) => {
  try {
    const response = await fetch(signedUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const text = await response.text(); // Get the response as text
    return text;
  } catch (error) {
    console.error(
      "myLog There has been a problem with your fetch operation: ",
      error
    );
  }
};

export const getArrayBufferFromUrl = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  } catch (error) {
    console.error(
      "There has been a problem with your fetch operation: ",
      error
    );
  }
};
