import React, { useEffect, useRef, useState } from 'react';
import './job-position.css';
import Resumes from './resumes';
import ShortListedVideoIntro from './shortlisted-video';
import { useNavigate, useParams } from 'react-router-dom';
import JobDescription from './job-description';
import {
  fetchAllowedFileTypes,
  fetchJobByID,
} from '../../../services/job-services';
import { useQuery } from 'hooks/useQuery';
import Spinner from 'react-bootstrap/Spinner';

const JobDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(false);

  const queryTab = query.get('tab') || 'job-description';

  const updateTabToQuery = (key) => {
    navigate(`/job-details/${params.jobId}?tab=${key}`);
  };

  const [job, setJob] = useState({});
  const [shortlistedCanditates, setShortlistedCanditates] = useState([]);
  const getJobById = async () => {
    setIsLoading(true);
    const jb = await fetchJobByID({ job_id: params.jobId });
    let candidate = [];
    jb.data.candidates.forEach((item) => {
      if (item.status.toLowerCase().includes('video')) {
        candidate.push({
          ...item,
          ...item.video_score,
          name: item.name,
          match_score: item.match_score,
          resume_id: item.id,
          retain_score: item.retain_score,
        });
      }
    });
    setShortlistedCanditates(candidate);
    setJob({ ...jb.data });
    setIsLoading(false);
  };

  const [fileTypes, setFileTypes] = useState({});
  const getAllowedFilesTypes = async () => {
    try {
      const types = await fetchAllowedFileTypes();
      setFileTypes({ ...types.data });
    } catch (error) {
      console.log(error);
    }
  };

  const [refreshJob, setRefreshJob] = useState(true);

  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    if (refreshJob) {
      console.log({ refreshJob });
      refreshJob && console.log('UPDATED');
      getJobById();
      getAllowedFilesTypes();
      setRefreshJob(false);
    }
  }, [refreshJob]);

  const resumeCandidates = job.candidates?.filter(
    (candidate) => !candidate.status?.toLowerCase().includes('video')
  );

  if (isLoading) {
    return (
      <div
        style={{
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner animation="border" role="status" variant="primary" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="stickyTop mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="tabs">
              <span
                className={queryTab === 'job-description' && 'active'}
                onClick={() => {
                  updateTabToQuery('job-description');
                }}
              >
                Job Description
              </span>
              {Object.keys(job).length > 0 && (
                <React.Fragment>
                  <span
                    className={queryTab === 'resumes' && 'active'}
                    onClick={() => {
                      updateTabToQuery('resumes');
                    }}
                  >
                    Resumes{' '}
                    {resumeCandidates.length
                      ? `(${resumeCandidates.length})`
                      : ''}
                  </span>
                  <span
                    className={queryTab === 'invitations' && 'active'}
                    onClick={() => {
                      updateTabToQuery('invitations');
                    }}
                  >
                    Shortlisted for Video introduction{' '}
                    {job.video_count ? `(${job.video_count})` : ''}
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {queryTab === 'job-description' && (
          <JobDescription
            jobs={job}
            fileType={fileTypes.resume || {}}
            job_id={params.jobId}
            refreshJob={setRefreshJob}
          />
        )}
        {queryTab === 'resumes' && (
          <Resumes
            candidates={resumeCandidates}
            status={job.status}
            job_id={params.jobId}
            refreshJob={setRefreshJob}
            jobs={job}
          />
        )}
        {queryTab === 'invitations' && (
          <ShortListedVideoIntro
            candidates={shortlistedCanditates}
            trait={job.traits}
            status={job.status}
            job_id={params.jobId}
            job={job}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
