import api from "../../config/backend-url"

export const fetchSignupOtp = (email) => {
    return new Promise((resolve, reject) => {
        api.post('user/generate_signup_otp', { 'email': email })
            .then(res => {
                if (res.data.response_code === -1) {
                    reject(res.data.message)
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const userRegistration = (data) => {
    return new Promise((resolve, reject) => {
        api.post('user/register', data)
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const userLogin = (data) => {
    return new Promise((resolve, reject) => {
        api.post('user/login', data, { withCredentials: true })
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getForgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        api.post('user/init_forgot_password', { 'email': email })
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getCompanyName = (data) => {
    return new Promise((resolve, reject) => {
        api.post('company/get_company', { 'company_access_key': data })
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const fetchCompanyRegistrationOtp = (email) => {
    return new Promise((resolve, reject) => {
        api.post('company/generate_signup_otp', { 'email': email })
            .then(res => {
                if (res.data.response_code === -1) {
                    reject(res.data.message)
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const companyRegistration = (data) => {
    return new Promise((resolve, reject) => {
        api.post('company/register', data)
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getUserDetails = (data) => {
    return new Promise((resolve, reject) => {
        api.post('user/get_details', data)
            .then(res => {
                if (res.data.response_code === 401) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getProfileDetails = (data) => {
    return new Promise((resolve, reject) => {
        api.post('user/profile_get', data)
            .then(res => {
                if (res.data.response_code === 401) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}


export const userLogout = (data) => {
    return new Promise((resolve, reject) => {
        api.post('user/logout', data)
            .then(res => {
                if (res.data.response_code === -1) reject(res.data.message)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}