const TermsCondition = () => {
    return (
        <div className="p-3 pt-1">
            <h2 className="mb-4">Terms & Conditions Agreement</h2>
            <h6 className="mb-4">This Terms & Conditions Agreement ("Agreement") is made and entered into by and between Fairee Technologies Pte Ltd ("Provider"), and the entity or individual ("Customer") agreeing to the terms and conditions herein.</h6>
            <ol>
                <li className="mb-3">Acceptance of Terms: By registering for, accessing or using the Provider's software-as-a-service ("Service"), Customer agrees to be bound by this Agreement. If Customer does not agree to these terms, Customer should not use the Service.</li>
                <li className="mb-3">Service: Provider agrees to provide the Service to Customer, subject to the terms and conditions of this Agreement. The Service includes the software, technology, and intellectual property owned by the Provider.</li>
                <li className="mb-3"> Use of Service: Customer may use the Service solely for its internal business purposes. Customer may not use the Service for any illegal or unauthorized purpose or in any way that violates any law or regulation. Customer shall not rent, lease, sublicense, transfer, or distribute the Service to any third party.</li>
                <li className="mb-3">Payment: Customer agrees to pay Provider the fees for use of the Service as set forth in the applicable order form. Fees are non-refundable. Provider reserves the right to change the fees for the Service with reasonable advance notice.</li>
                <li className="mb-3">Proprietary Rights: Provider retains all rights, title, and interest in and to the Service and any intellectual property related thereto. Customer may not use any of Provider's trademarks, logos, or service marks without Provider's prior written consent.</li>
                <li className="mb-3">Confidentiality: Customer agrees to keep the Service and any other proprietary information of Provider confidential and not disclose such information to any third party without Provider's prior written consent.</li>
                <li className="mb-3">Warranties and Disclaimers: Provider represents and warrants that it has the right to provide the Service and that the Service will operate in accordance with its documentation. EXCEPT AS SET FORTH IN THIS SECTION, THE SERVICE IS PROVIDED "AS IS" AND PROVIDER MAKES NO OTHER REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
                <li className="mb-3">Limitation of Liability: PROVIDER SHALL NOT BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE USE OF THE SERVICE, WHETHER IN CONTRACT, TORT, OR OTHERWISE, EVEN IF PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                <li className="mb-3">Term and Termination: This Agreement shall remain in effect until terminated by either party upon written notice. Provider may terminate this Agreement immediately upon notice if Customer breaches any material term or condition of this Agreement.</li>
                <li className="mb-3">Governing Law: This Agreement shall be governed by and construed in accordance with the laws of the state where Provider's headquarters are located, without regard to its conflict of law provisions</li>
                <li className="mb-3">Entire Agreement: This Agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous communications and proposals, whether oral or written, relating to the Service.</li>
                <li className="mb-3">Amendments: Provider may amend this Agreement at any time by providing reasonable notice to Customer. Customer's continued use of the Service after such notice constitutes acceptance of the amended terms.</li>
                <li className="mb-3">Assignment: This Agreement may not be assigned by either party without the prior written consent of the other party.</li>
                <li className="mb-3">Notices: Any notices under this Agreement shall be in writing and shall be sent to the addresses set forth in the order form or such other address as either party may specify in writing.</li>
            </ol>
            <h6 className="my-4">IN WITNESS WHEREOF, the parties have executed this Privacy Policy as of the date of registration for the Service.</h6>
            <p><b>Fairee Technologies Pte Ltd</b> <br></br>
                By: Debashis Tarafdar <br></br>
                Title: Founder and Chief Executive Officer <br></br>
                31 May 2023</p>
        </div>
    )
}

export default TermsCondition; <br></br>