import './job-position.css';
import AlertIcon from '../../../../assets/images/alert_icon.svg';
import GreenTick from '../../../../assets/images/green-tick.svg';
import PredictorBlueImg from '../../../../assets/images/predictorBlue.svg';
import PredictorYellowImg from '../../../../assets/images/predictorYellow.svg';
import PredictorGreenImg from '../../../../assets/images/predictorGreen.svg';
import ResumePreview from '../../../../assets/images/resume.png';
import {
  inviteCandidateVideo,
  updateCanditateData,
} from '../../../services/candidate-services';
import { Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { fetchJobByID } from '../../../services/job-services';
import { toastr } from 'react-redux-toastr';
import { emailChecker } from '../../../shared/helper';
import Resume from './Resume';
import Recruiter from './invitationTemplates/recruiter.jsx';
import InternalHire from './invitationTemplates/internalHire';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import api from 'config/backend-url';
import dayjs from 'dayjs';

/* 
  TODO: Date picker clear date issue
*/

const Resumes = (props) => {
  const dataFetchedRef = useRef(false);

  const [show, setShow] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    sample_invite: false,
    success_modal: false,
  });

  const [invitationSentSuccess, setInvitationSentSuccess] = useState(false);

  const toggleModal = (state, type) => {
    setShow((prevState) => ({
      ...prevState,
      [state]: type === 'close' ? false : true,
    }));
  };

  const date = new Date();
  date.setDate(date.getDate() + 5);

  const [candidate, setCanditate] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [viewedCandidateId, setViewedCandidateId] = useState(null);
  const [invitedSentNum, setInvitedSentNum] = useState(0);
  const [startDate, setStartDate] = useState(date);

  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    let cArray = props.candidates.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    cArray.sort(
      (a, b) =>
        Math.round(b.match_score * 0.8 + b.retain_score * 0.2) -
        Math.round(a.match_score * 0.8 + a.retain_score * 0.2)
    );
    setCanditate(cArray);
  }, [props]);

  const editEmail = () => {};

  const [selectedCanditate, setSelectedCanditate] = useState([]);
  const toggleCheckbox = (value, id, type) => {
    let tempArray = [];
    console.log({ ...candidate });
    let cArray = candidate.map((item, index) => {
      if (type === 'ALL') {
        if (value) tempArray.push(item.id);
        else tempArray = [];
        return {
          ...item,
          isChecked: value,
        };
      }
      if (type === 'selected') {
        if (item.id === id) {
          tempArray = selectedCanditate.length > 0 ? selectedCanditate : [];
          if (value) tempArray.push(id);
          else
            tempArray.splice(
              tempArray.findIndex((x) => x.id === item.id),
              1
            );
        }
        return {
          ...item,
          isChecked: item.id === id ? value : item.isChecked,
        };
      }
      if (type === 'quick-select') {
        if (!value) {
          tempArray = [];
        } else if (index < Math.ceil((candidate.length * id) / 100)) {
          // tempArray = quickSelectCheck && [];
          tempArray.push(item.id);
        }
        return {
          ...item,
          isChecked:
            index < Math.ceil((candidate.length * id) / 100) && value
              ? true
              : false,
        };
      }
      return {
        ...item,
        isChecked: false,
      };
    });
    console.log({ ...tempArray });
    setCanditate(cArray);
    setSelectedCanditate(tempArray);
  };

  const inviteForVideo = async () => {
    try {
      setInvitedSentNum(selectedCanditate.length);
      const currentDate = dayjs();
      const givenDate = dayjs(startDate); // replace with your given date
      const dead_Line = givenDate.diff(currentDate, 'day');

      const invite = await inviteCandidateVideo({
        candidate_ids: selectedCanditate,
        dead_Line,
      });
      console.log('invite', invite);
      setInvitationSentSuccess(true);
    } catch (error) {
      console.log(error);
      toastr.error(error.message);
    }
  };

  const [updatedEmail, setUpdatedEmail] = useState({});
  const [emailId, setEmailId] = useState({});
  const [emailError, setEmailError] = useState({ isError: false, message: '' });
  const [isUpdating, setIsUpdating] = useState(false);

  const updateEmail = async () => {
    if (Object.keys(updatedEmail).length > 0) {
      if (!emailError.isError)
        try {
          setIsUpdating(true);
          const ue = await updateCanditateData({ ...emailId, ...updatedEmail });
          if (ue) {
            toggleModal('modal1', 'close');
            setUpdatedEmail({});
            toggleModal('success_modal', 'open');
          }
        } catch (error) {
          toastr.error(error);
        } finally {
          setIsUpdating(false);
        }
    } else {
      setEmailError({ isError: true, message: 'Email ID cannot be empty' });
    }
  };

  const [quickSelectRange, setQuickSelectedRange] = useState({
    10: false,
    20: false,
    30: false,
    40: false,
    50: false,
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadInitialShortList = async () => {
    console.log('selectedCanditate', selectedCanditate);
    try {
      setIsDownloading(true);
      const res = await api.post('/candidate/send_resume_email', {
        canddidate_ids: selectedCanditate,
        job_id: props.job_id,
        initial: true,
      });
      console.log('res', res);
      toggleModal('modal7', 'open');
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div>
        <div className="stickyTop mt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="tableControl">
                <div
                  className="tableConLeft"
                  style={{
                    flex: 1,
                  }}
                >
                  <div className="rowSelectors">
                    <p className="smallBold">Quick Select</p>
                    <div id="resumeFilter">
                      <p className="smallBold">Top</p>
                      <button
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(true, 10, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            10: true,
                          });
                        }}
                      >
                        10%
                      </button>
                      <button
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(true, 20, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            20: true,
                          });
                        }}
                      >
                        20%
                      </button>
                      <button
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(true, 30, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            30: true,
                          });
                        }}
                      >
                        30%
                      </button>
                      <button
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(true, 40, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            40: true,
                          });
                        }}
                      >
                        40%
                      </button>
                      <button
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(true, 50, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            50: true,
                          });
                        }}
                      >
                        50%
                      </button>
                      <button
                        className={
                          'clear ' +
                          (props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : '')
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        onClick={() => {
                          toggleCheckbox(false, 0, 'quick-select');
                          setQuickSelectedRange({
                            ...quickSelectRange,
                            60: true,
                          });
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="tableSelectProgress">
                    <p className="smallBold mb-0">Selected</p>
                    <div
                      className="progressWrap"
                      style={{
                        width: '250px',
                      }}
                    >
                      <span className="smallBold">0</span>
                      <div className="w-100">
                        <div
                          className="progress cstmProgessResume"
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow={Object.keys(selectedCanditate).length}
                          aria-valuemin="0"
                          aria-valuemax={candidate.length}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              width:
                                (Object.keys(selectedCanditate).length /
                                  candidate.length) *
                                  100 +
                                '%',
                            }}
                          ></div>
                        </div>
                        <div
                          className="progress-bar-tooltip"
                          style={{
                            paddingLeft:
                              (Object.keys(selectedCanditate).length /
                                candidate.length) *
                                100 +
                              '%',
                            visibility:
                              Object.keys(selectedCanditate).length > 0
                                ? 'visible'
                                : 'hidden',
                          }}
                        >
                          {Object.keys(selectedCanditate).length}
                        </div>
                      </div>
                      <span className="smallBold">100%</span>
                    </div>
                  </div>
                </div>
                <div
                  className="tableConRight d-flex justify-content-end"
                  style={{
                    flex: 1,
                  }}
                >
                  <div>
                    <div className="d-flex gap-2">
                      <button
                        className={
                          selectedCanditate.length === 0 || isDownloading
                            ? 'disables smallCTAprimary'
                            : 'smallCTAprimary cursorP'
                        }
                        onClick={handleDownloadInitialShortList}
                        disabled={
                          selectedCanditate.length === 0 || isDownloading
                        }
                      >
                        {isDownloading
                          ? 'Downloading...'
                          : 'Download Initial Shortlist'}
                      </button>
                      <button
                        className={
                          selectedCanditate.length === 0
                            ? 'disables smallCTAprimary'
                            : 'smallCTAprimary cursorP'
                        }
                        onClick={() => toggleModal('modal6', 'open')}
                        disabled={selectedCanditate.length === 0}
                      >
                        Invite for Video Introduction
                      </button>
                    </div>
                    <p
                      className="mt-1  text-right xsCTA actives cursorP"
                      onClick={() => toggleModal('sample_invite', 'open')}
                      style={{
                        marginRight: '36px',
                      }}
                    >
                      View Sample Invitation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className="table resumeTable">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  checked={
                    candidate.length > 0 &&
                    selectedCanditate.length === candidate.length
                  }
                  onChange={(e) => toggleCheckbox(e.target.checked, '', 'ALL')}
                  disabled={
                    props.status === 'CLOSED' || props.status === 'ARCHIVED'
                  }
                  className={
                    props.status === 'CLOSED' || props.status === 'ARCHIVED'
                      ? 'disables'
                      : ''
                  }
                />
              </th>
              <th scope="col">Rank</th>
              <th scope="col">Candidate Name</th>
              <th scope="col">Email Id</th>
              <th scope="col">
                Resume
                <br />
                Match
              </th>
              <th scope="col">
                Retention
                <br />
                Predictor
              </th>
              <th scope="col">
                Overall
                <br />
                Score
              </th>
            </tr>
          </thead>
          <tbody style={{ whiteSpace: 'nowrap' }}>
            {candidate.length > 0 &&
              candidate.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={(e) =>
                          toggleCheckbox(e.target.checked, item.id, 'selected')
                        }
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                        className={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : ''
                        }
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <span className="rank">{index + 1}</span>
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <span
                        className={`actives smallSubT cursorP ${
                          viewedCandidateId === item.id
                            ? 'fw-bolder text-decoration-underline'
                            : ''
                        } ${
                          item.name || item.name_extracted_from_resume
                            ? ''
                            : 'text-red'
                        }`}
                        onClick={() => {
                          if (item.uploads?.RESUME) {
                            toggleModal('modal5', 'open');
                            setSelectedResume(item.uploads?.RESUME);
                            setViewedCandidateId(item.id);
                          }
                          console.log('myLog item');
                        }}
                      >
                        {item.name ||
                          item.name_extracted_from_resume ||
                          'Resume Link'}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <span className="text-16">
                        {item.status === 'RESUME_UPLOADED' ||
                        item.status === 'RESUME_ANALYSING'
                          ? 'Processing'
                          : item.status === 'RESUME_ANALYSED' ||
                            item.status === 'VIDEO_ANALYSED'
                          ? item.email === 'Not Present' || item.email === ''
                            ? 'Not Detected'
                            : item.email
                          : item.status === 'RESUME_ANALYSING_FAILED'
                          ? 'Could not be processed'
                          : 'Not Detected'}
                      </span>
                      <button
                        className={
                          'xsSecondary ml-1 py-0 px-2 ' +
                          (props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                            ? 'disables'
                            : '')
                        }
                        onClick={() => {
                          toggleModal('modal1', 'open');
                          setEmailId({ candidate_id: item.id });
                          setUpdatedEmail({
                            email: item.email,
                            name: item.name,
                          });
                          setEmailError({
                            isError:
                              emailChecker(item.email).length > 0
                                ? true
                                : false,
                            message:
                              emailChecker(item.email).length > 0
                                ? emailChecker(item.email)[0].message
                                : '',
                          });
                        }}
                        disabled={
                          props.status === 'CLOSED' ||
                          props.status === 'ARCHIVED'
                        }
                      >
                        Edit
                      </button>
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <b>{Math.round(Number(item.match_score)) + '%' || 0}</b>
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <img
                        src={
                          Number(item.retain_score) >= 80
                            ? PredictorGreenImg
                            : Number(item.retain_score) >= 50 &&
                              Number(item.retain_score) < 80
                            ? PredictorBlueImg
                            : PredictorYellowImg
                        }
                        alt=""
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: item.isChecked && 'rgb(240 245 255)',
                      }}
                    >
                      <b>
                        {Math.round(
                          item.match_score * 0.8 + item.retain_score * 0.2
                        ) + '%' || 0}
                      </b>
                    </td>
                  </tr>
                );
              })}
            {candidate.length === 0 && (
              <tr>
                <td colSpan={7}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Modal Email Sample */}
      <div
        className="modal fade"
        id="emailSample"
        tabIndex="-1"
        aria-labelledby="emailSample"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Sample Email Template</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="textWrapBor">
                From : noreply@fairee.com.sg
                <br />
                {/* Reply to : <User Email ID /><br /> */}
                {/* Cc : <User Email ID /><br /><br /> */}
                {/* Email Subject: Your application for the position of <Position Name /> at <Company Name /><br /><br /> */}
                Dear Applicant,
                <br />
                <br />
                {/* You are receiving this email via Fairee Technologies Pte Ltd on behalf of <Company Name />. <br /><br /> */}
                Thank you for submitting your resume for the position of:
                <br />
                <br />
                Position Name : <br />
                Company Name : <br />
                <br />
                We have reviewed your resume and have shortlisted you for the
                next stage of our recruitment process.
                <br />
                <br />
                As part of our Fair Employment Practices initiative, we strive
                to provide equal opportunity to all deserving candidates. You
                are requested to upload a short 3-4 minute self-introduction
                video for us to know you better.
                <br />
                <br />
                Kindly introduce yourself and answer the following question in
                your video :<br />
                <br />
                Q. In what ways do you believe that your professional background
                and skill set align with the requirements of the position you
                have applied for? 
                <br />
                <br />
                Please record your video with clear audio and in a well-lit
                environment (with your front face clearly visible), on your
                desktop, laptop or mobile/tablet, and follow the instructions as
                mentioned below to upload the video by the submission deadline.
                Only the videos sent via the upload link will be accepted. The
                upload site is managed by our recruitment services partner,
                Fairee Technologies Private Limited. 
                <br />
                <br />
                Video Upload Link :
                {/* <personalized link to upload video /><br /> */}
                Submission Deadline :{/* <date /><br /> */}
                Video Duration : 3-4 minutes
                <br />
                Video Size Limit : 150 MB 
                <br />
                <br />
                We wish you all the best for the next stage of this journey. We
                will contact you if you are selected for the subsequent stages
                of this recruitment process.
                <br />
                <br />
                Please do not hesitate to contact us by replying to this email
                only if you face any technical difficulties in uploading your
                video.
                <br />
                <br />
                Best regards,
                <br />
                <br />
                Name of User
                <br />
                Company Name
                <br />
                <br />
                Note: This is a system generated email. Inadvertent errors and
                omissions due to system issues are regretted. If you are not the
                intended recipient of this email, please let us know at
                support@fairee.com.sg.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Edit Email */}
      <Modal
        show={show.modal1}
        onHide={() => toggleModal('modal1', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="w-100 btn-close-flex p-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal('modal1', 'close')}
            ></button>
          </div>
          <Form className="formSet" onSubmit={editEmail}>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  value={
                    Object.keys(updatedEmail).length > 0 && updatedEmail.name
                  }
                  onChange={(e) => {
                    console.log('myLog e', e.target.value);
                    setUpdatedEmail((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="">Email Id</label>
                <input
                  type="text"
                  placeholder="Email"
                  value={
                    Object.keys(updatedEmail).length > 0 && updatedEmail.email
                  }
                  onChange={(e) => {
                    setUpdatedEmail((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                    setEmailError({
                      isError:
                        emailChecker(e.target.value).length > 0 ? true : false,
                      message:
                        emailChecker(e.target.value).length > 0
                          ? emailChecker(e.target.value)[0].message
                          : '',
                    });
                  }}
                />
              </div>
            </div>
            {emailError.isError ? (
              <div className="error-toaster">{emailError.message}</div>
            ) : null}
            <div className="dualCTA">
              <span
                className="bigCTAprimary w-50 mt-3"
                onClick={() => {
                  toggleModal('modal1', 'close');
                  setEmailError({ isError: false });
                }}
              >
                Cancel
              </span>
              <button
                type="button"
                className={
                  'smallCTAsecondary w-50 mt-3 cursorP' +
                  (emailError.isError && ' disables')
                }
                disabled={emailError.isError || isUpdating}
                onClick={() => updateEmail()}
              >
                {isUpdating ? 'Updating...' : 'Update'}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal Invite */}
      <Modal
        show={show.modal2}
        onHide={() => toggleModal('modal2', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal2', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src={AlertIcon} alt="" />
          </p>
          <h3>Are you sure?</h3>
          <p>
            The selected {selectedCanditate.length} candidates will be invited
            for submitting video introduction{' '}
            <strong>(deadline {startDate.toDateString()})</strong>
          </p>
          <div className="dualCTA">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => toggleModal('modal2', 'close')}
            >
              No
            </p>
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => {
                inviteForVideo();
                toggleModal('modal2', 'close');
                toggleCheckbox(false, 0, 'quick-select');
              }}
            >
              Yes
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal6}
        onHide={() => toggleModal('modal6', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal6', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <h3>Choose a submission deadline</h3>
          <div className="mb-2 mt-4">
            <DatePicker
              selected={startDate}
              onChange={(date) => date && setStartDate(date)}
              wrapperClassName="w-full"
              className="w-half"
              dateFormat="dd MMM yyyy"
            />
          </div>
          <div className="dualCTA">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => {
                toggleModal('modal6', 'close');
                toggleModal('modal2', 'open');
              }}
            >
              Ok
            </p>
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => toggleModal('modal6', 'close')}
            >
              Cancel
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* Sample template */}
      <Modal
        show={show.sample_invite}
        onHide={() => toggleModal('sample_invite', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('sample_invite', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center d-flex justify-content-center">
          {props.jobs.client_name ? <Recruiter /> : <InternalHire />}
        </Modal.Body>
      </Modal>

      {/* Modal Resume Sample */}
      <Modal
        show={show.modal5}
        onHide={() => {
          toggleModal('modal5', 'close');
          setSelectedResume(null);
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="resume-page"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal5', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <Resume resumeObj={selectedResume} />
        </Modal.Body>
      </Modal>

      {/* Modal Invitation Sucessful */}
      <Modal
        show={invitationSentSuccess}
        onHide={() => {
          setInvitedSentNum(0);
          setInvitationSentSuccess(false);
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="invitation-success"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setInvitedSentNum(0);
              setInvitationSentSuccess(false);
            }}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src="/images/green-tick.svg" alt="" />
          </p>
          <h3>Invitation sent successfully!</h3>
          <p>
            The selected {invitedSentNum} resumes have been moved to the
            Shortlisted tab.
          </p>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                setInvitationSentSuccess(false);
                setInvitedSentNum(0);

                props.refreshJob(true);
              }}
              className="bigCTAprimary"
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal7}
        onHide={() => toggleModal('modal7', 'close')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal('modal7', 'close')}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>Thank You. The Initial Shortlist has been sent to your email.</p>
          <Button
            onClick={() => toggleModal('modal7', 'close')}
            style={{ width: '100px' }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.success_modal}
        onHide={() => {
          toggleModal('success_modal', 'close');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="invitation-success"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              toggleModal('success_modal', 'close');
            }}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src="/images/green-tick.svg" alt="" />
          </p>
          <h3>Name/email changed successfully</h3>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                toggleModal('success_modal', 'close');
                props.refreshJob(true);
              }}
              className="bigCTAprimary"
              style={{
                width: '100px',
              }}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Resumes;
