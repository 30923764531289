import { toastr } from "react-redux-toastr";

import api from "config/backend-url";

export const getDashboardData = async () => {
  try {
    const response = await api.post("user/dashboard");
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    toastr.error("Error", error.message, {
      timeOut: 5000,
      position: "top-right",
    });
    return {
      success: false,
      message: error.message,
    };
  }
};

export const getUserBalance = async () => {
  try {
    const response = await api.post("company/credit_get");
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    toastr.error("Error", error.message, {
      timeOut: 5000,
      position: "top-right",
    });
    return {
      success: false,
      message: error.message,
    };
  }
};

export const profileUpdate = async (data) => {
  try {
    const response = await api.post("user/profile_update", data);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    toastr.error("Error", error.message, {
      timeOut: 5000,
      position: "top-right",
    });
    return {
      success: false,
      message: error.message,
    };
  }
};

export const getVideoUploaderData = async (tokenId) => {
  try {
    const response = await api.post("candidate/info_from_intro_video_token", {
      intro_video_token: tokenId,
    });
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    toastr.error("Error", error.message, {
      timeOut: 5000,
      position: "top-right",
    });
    return {
      success: false,
      message: error.message,
    };
  }
};

export const resetPassword = async (payload) => {
  try {
    const response = await api.post("user/update_password", payload);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    toastr.error("Error", error.message, {
      timeOut: 5000,
      position: "top-right",
    });
    return {
      success: false,
      message: error.message,
    };
  }
};
