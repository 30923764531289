import React, { useCallback, useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Spinner from "react-bootstrap/Spinner";
import { toastr } from "react-redux-toastr";

import styles from "./style.module.css";
import {
  getArrayBufferFromUrl,
  getBlobData,
  getSignedUrl,
} from "container/services/job-services";
import { extensionToMimeTypeMap } from "container/services/constant";

const FileViewer = ({ bucketKey = "" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileURI, setFileURI] = useState("");
  const [arrayBufferFileData, setArrayBufferFileData] = useState();

  const extType = bucketKey.split(".").pop();

  const fetchFileData = useCallback(
    async (bucketKey) => {
      try {
        const signedUrlResponse = await getSignedUrl(bucketKey);
        console.log("myLog signedUrlResponse", signedUrlResponse);
        if (!signedUrlResponse.success) {
          toastr.error("Something went wrong with signed URL");
          return;
        }

        const singedUrl = signedUrlResponse.singedUrl;

        if (["doc", "docx"].includes(extType)) {
          setFileURI(singedUrl);
          return;
        }

        console.log("singedUrl", singedUrl);

        const blobData = await getBlobData(singedUrl, extType);

        const arrayBufferData = await getArrayBufferFromUrl(singedUrl);

        const fileURL = URL.createObjectURL(blobData);

        setFileURI(fileURL);

        setArrayBufferFileData(arrayBufferData);

        console.log("myLog fileURL", fileURL);
      } catch (error) {
        toastr.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [extType]
  );

  useEffect(() => {
    if (bucketKey) {
      fetchFileData(bucketKey);
    }
  }, [bucketKey, fetchFileData]);

  if (isLoading) {
    return (
      <div className={styles.loader_container}>
        <Spinner animation="border" role="status" variant="primary" />
      </div>
    );
  }

  const docs = [
    {
      uri: fileURI,
      fileType: extensionToMimeTypeMap[extType],
    },
  ];

  return (
    <div>
      <DocViewer
        style={
          ["doc", "docx"].includes(extType)
            ? { width: "100%", height: "50vw", fontSize: "10px !important" }
            : {}
        }
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: { disableHeader: true },
          pdfZoom: {
            defaultZoom: 1, // 1 as default,
            zoomJump: 0.2, // 0.1 as default,
          },
        }}
        className="my-doc-viewer-style"
      />
    </div>
  );
};

export default React.memo(FileViewer);
