import { useNavigate, useParams } from 'react-router-dom';
import './job-position.css';
import React, { useEffect } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { useRef } from 'react';
import { useState } from 'react';
import {
  updateCanditateData,
  uploadCanditateVideo,
} from '../../../services/candidate-services';
import { toastr } from 'react-redux-toastr';
import { fetchAllowedFileTypes } from '../../../services/job-services';
import GreenTick from '../../../../assets/images/green-tick.svg';
import { useGetVideoUploader } from 'hooks/useVideoUploader';
import api from 'config/backend-url';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const UploadVideo = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const { isLoading, data, refetch } = useGetVideoUploader(videoId);

  const userData = data?.data?.data;

  const dataFetchedRef = useRef(false);

  const [show, setShow] = useState({ modal1: false, modal2: false });
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [completedPerc, setCompletedPerc] = useState(0);

  const toggleModal = (state, type) => {
    setShow((prevState) => ({
      ...prevState,
      [state]: type === 'close' ? false : true,
    }));
  };

  const [formData, setFormData] = useState({ isChecked: false });

  const updateForm = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  const [fileTypes, setFileTypes] = useState({});
  const getAllowedFilesTypes = async () => {
    try {
      const types = await fetchAllowedFileTypes();
      setFileTypes(types.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [fileError, setFileError] = useState({ isError: false, message: '' });
  const [file, setFile] = useState([]);
  const checkFile = (file, type) => {
    setFileError({ isError: false });
    if (
      Array(file.target.files).length > 0 &&
      fileTypes &&
      fileTypes.video.filter_mimetype.includes(file.target.files[0].type)
    ) {
      const megabytes = 150; // 150 MB
      const bytes = megabytes * (1024 * 1024);

      if (file.target.files[0].size > bytes) {
        setFileError({
          isError: true,
          message: 'File Size Large. Max is 150 MB',
        });
        return;
      }

      setFile(file.target.files);
      updateForm('video', file.target.files[0]);
      setFileError({ isError: false });
    } else setFileError({ isError: true, message: 'File type not supported.' });
  };

  const [updatedName, setUpdatedName] = useState({});
  const updateEmail = async (e) => {
    try {
      e.preventDefault();

      setIsUpdating(true);
      const res = await updateCanditateData({
        candidate_id: userData.id,
        name: updatedName.name,
      });

      if (res.data === 'OK') {
        refetch();
        toggleModal('modal1', 'close');
      }
    } catch (error) {
      toastr.error('', error);
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const uploadVideo = async () => {
    if (fileError.isError || file.length === 0 || !formData.isChecked) {
      toastr.error('Form Error');
    } else {
      try {
        const fd = new FormData();
        fd.append('token', formData.token);
        fd.append('video', formData.video);
        fd.append('name', userData.name);

        const config = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setCompletedPerc(percentCompleted);
            console.log('percentCompleted', percentCompleted);
          },
        };

        setIsProcessing(true);
        const response = await api.post(
          '/candidate/upload_intro_video',
          fd,
          config
        );

        console.log('response', response.data);
        if (response.data.response_code === -1) {
          toastr.error(response.data.message);
        } else {
          setShow((prevState) => ({
            ...prevState,
            modal2: true,
          }));
        }
        setFormData({});
      } catch (error) {
        toastr.error(error.message);
        setFormData({ isChecked: false });
        setFile([]);
        setFileError({ isError: true, message: 'Some Error Occurred' });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getAllowedFilesTypes();
    updateForm('token', videoId);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <p>Link expired or file already uploaded!</p>
      </div>
    );
  }
  console.log('userData', userData);

  return (
    <React.Fragment>
      <div className="p-5 ">
        <h1>Upload Your Video Introduction Here</h1>
        <p className="mt-3">Company Name - {userData.company.name}</p>
        <p>Job Position - {userData.job.title}</p>
        <p>
          Candidate Name - {userData.name}{' '}
          <button
            className="xsSecondary ml-1 px-2 py-0"
            onClick={() => toggleModal('modal1', 'open')}
          >
            Edit
          </button>
        </p>
        <p>Candidate Email Id - {userData.email}</p>
        <div>
          <div className="inputSet uploadFiles d-flex flex-column">
            <p>
              Drag and drop the video file
              {Object.values(fileTypes).length > 0 &&
                fileTypes.video.filter_extension.map((item) => {
                  return ' (' + item + ') ';
                })}
              here or click below to browse the file
            </p>
            <input
              type="file"
              placeholder="Choose File"
              accept={
                Object.keys(fileTypes).length > 0
                  ? fileTypes.video.filter_mimetype.toString()
                  : ''
              }
              onChange={(e) => checkFile(e, 'fileUpload')}
            />
            {fileError.isError ? (
              <div className="error-toaster mt-4">{fileError.message}</div>
            ) : null}
          </div>

          <div className="mt-3 d-flex align-items-start flex-row">
            <input
              type="checkbox"
              id="video-submit"
              checked={formData.isChecked}
              className="mt-1"
              onChange={(e) => updateForm('isChecked', e.target.checked)}
            />
            <div className="bodyM ml-1">
              <label htmlFor="video-submit">
                By clicking this check-box, you are providing your consent for
                us to collect and process your personal data for the purpose of
                evaluating your profile for the current position that you have
                applied for, and for improving the quality of such evaluation.
                We will not use your data for any other purposes unless we
                obtain your explicit consent or are required by law to do so.
                Your personal data will be stored securely and will not be
                shared with third parties unless it is necessary for the
                fulfillment of our services or if we are legally required to do
                so. You acknowledge that you have read and understood our{' '}
                <a href="#">privacy policy</a> and agree to our use of your
                personal data solely for the stated purpose.
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="smallCTAprimary mt-3"
            onClick={uploadVideo}
          >
            Submit
          </button>
        </div>
      </div>

      <Modal
        show={show.modal1}
        onHide={() => toggleModal('modal1', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="w-100 btn-close-flex p-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal('modal1', 'close')}
            ></button>
          </div>
          <Form className="formSet" onSubmit={updateEmail}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              placeholder="Name"
              onChange={(e) => setUpdatedName({ name: e.target.value })}
            />
            <div className="dualCTA">
              <span
                className="bigCTAprimary w-50 mt-3"
                onClick={() => !isUpdating && toggleModal('modal1', 'close')}
              >
                Cancel
              </span>
              <button
                type="submit"
                className="smallCTAsecondary w-50 mt-3 cursorP"
                disabled={isUpdating}
              >
                {isUpdating ? <Spinner animation="border" size="sm" /> : 'Save'}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal2 || isProcessing}
        onHide={() => toggleModal('modal2', 'close')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          {isProcessing ? (
            <>
              <h5 className="text-center mt-3">Processing Video</h5>
              <div className="d-flex justify-content-center mt-4">
                <div style={{ width: 200, height: 200, textAlign: 'center' }}>
                  <CircularProgressbar
                    value={completedPerc}
                    text={`${completedPerc}%`}
                    styles={buildStyles({
                      pathColor: '#437AF8',
                      backgroundColor: '#437AF8',
                      textColor: '#437AF8',
                    })}
                  />
                </div>
              </div>
              ;
            </>
          ) : (
            <>
              <img src={GreenTick} alt="" />
              <h5 className="text-center mt-3">
                Thank you! Video uploaded successfully{' '}
              </h5>
              <p className="mt-4">
                <button
                  className="smallCTAprimary"
                  onClick={() => {
                    toggleModal('modal2', 'close');
                    navigate('/login');
                  }}
                >
                  Ok
                </button>
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UploadVideo;
