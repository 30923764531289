import Layout from "./container/hoc/layout";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { Suspense, useEffect, useState } from "react";
import LandingPage from "./container/components/landing-page";
import Login from "./container/components/auth/login";
import Signup from "./container/components/auth/signup";
import SetPassword from "./container/components/auth/set-password";
import CompanyRegistration from "./container/components/auth/company-registration";
import Dashboard from "./container/components/main/dashboard";
import AddJobPosition from "./container/components/main/job-positions/add-job-position";
import JobDashboard from "./container/components/main/job-positions/job-dashboard";
import { getUserDetails } from "./container/services/auth-services";
import JobDetails from "./container/components/main/job-positions/job-details";
import UploadVideo from "./container/components/main/job-positions/upload-video";
import { connect } from "react-redux";
import { onLogin } from "./container/store/action";
import { toastr } from "react-redux-toastr";
import ProfilePage from "container/components/Profile";
import Spinner from "react-bootstrap/Spinner";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutUs from "container/components/about-us";
import ContactUs from "container/components/contact-us";
import InterestedPage from "container/components/interested";

/* 
  TODO: Refresh not working properly, redirecting to dashboard each time
*/

function App(props) {
  const [isLoading, setIsLoading] = useState(false);

  const isUserLoggedIn = async () => {
    setIsLoading(true);
    try {
      const data = await getUserDetails();
      const account = {
        ...data.data,
        isLoggedIn: true,
        login: { email: data.data.email },
      };
      props.onLoggedIn(account);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    isUserLoggedIn();
    AOS.init();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status" variant="primary" />
      </div>
    );
  }

  return (
    <Layout>
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="/login"
            element={
              props.accounts.isLoggedIn ? (
                <Navigate to="/dashboard"></Navigate>
              ) : (
                <Login></Login>
              )
            }
          ></Route>
          <Route
            path="/signup"
            element={
              props.accounts.isLoggedIn ? (
                <Navigate to="/dashboard"></Navigate>
              ) : (
                <Signup></Signup>
              )
            }
          ></Route>
          <Route
            path="/setpassword/:token"
            element={
              props.accounts.isLoggedIn ? (
                <Navigate to="/dashboard"></Navigate>
              ) : (
                <SetPassword></SetPassword>
              )
            }
          ></Route>

          <Route
            path="/company-registration"
            element={
              props.accounts.isLoggedIn ? (
                <Navigate to="/dashboard"></Navigate>
              ) : (
                <CompanyRegistration></CompanyRegistration>
              )
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              props.accounts.isLoggedIn ? (
                <Dashboard></Dashboard>
              ) : (
                <Navigate to="/login"></Navigate>
              )
            }
          ></Route>
          <Route
            path="/job-dashboard"
            element={
              props.accounts.isLoggedIn ? (
                <JobDashboard></JobDashboard>
              ) : (
                <Navigate to="/login"></Navigate>
              )
            }
          ></Route>
          <Route
            path="/profile"
            element={
              props.accounts.isLoggedIn ? (
                <ProfilePage />
              ) : (
                <Navigate to="/login"></Navigate>
              )
            }
          ></Route>
          <Route
            path="/add-job"
            element={
              props.accounts.isLoggedIn ? (
                <AddJobPosition></AddJobPosition>
              ) : (
                <Navigate to="/login"></Navigate>
              )
            }
          ></Route>
          <Route
            path="/job-details/:jobId"
            element={
              props.accounts.isLoggedIn ? (
                <JobDetails></JobDetails>
              ) : (
                <Navigate to="/login"></Navigate>
              )
            }
          ></Route>
          <Route
            path="/video_upload/:videoId"
            element={<UploadVideo></UploadVideo>}
          ></Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoggedIn: (data) => dispatch(onLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
