import './common-header.css';
import SearchIcon from '../../../../assets/images/search_icon.svg';
import NotificationIcon from '../../../../assets/images/notificaion_icon.svg';
import Hamburger from '../../../../assets/images/hamburger.svg';
import ProfileIcon from '../../../../assets/images/profileBlue_icon.svg';
import CoinUp from '../../../../assets/images/coinUp.svg';
import { NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { onNavigate } from '../../../store/action';

import { useEffect } from 'react';
import { useGetBalance } from 'hooks/useGetBalance';
import { useGetProfileDetails } from 'hooks/useGetProfileDetails';

const CommonHeader = (props) => {
  const params = useParams();

  const { isLoading, data, refetch } = useGetBalance();
  const { isLoading: isProfileDetailsLoading, data: profileDetailsData } =
    useGetProfileDetails();

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  if (isLoading || isProfileDetailsLoading) return null;

  const remianingBalance =
    (data?.data?.data?.total_credits || 0) -
    (data?.data?.data?.credits_used || 0);

  const myProfile = profileDetailsData?.data?.my_profile;
  console.log('profileDetailsData', props.accounts);

  return (
    <header className="stickyTop mt-3">
      <nav className="navbar navbar-expand-md">
        <div className="container-fluid">
          <div>
            {!!props.accounts.subHeading && (
              <p
                style={{
                  color: '#8F8F8F',
                  fontSize: '14px',
                  fontFamily: 'Manrope',
                  fontWeight: '600',
                  textTransform: 'uppercase',
                  lineHeight: '22px',
                  wordWrap: 'break-word',
                  margin: 0,
                }}
              >
                {props.accounts.subHeading}
              </p>
            )}
            <h3
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {props.accounts.pageHeading}
            </h3>
          </div>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={Hamburger} alt="" />
          </button>
          <div
            className="navbar-collapse collapse bg-white p-2 pb-3 pt-3"
            id="navbarCollapse"
          >
            <ul className="navbar-nav ms-auto flex-nowrap">
              <li className="nav-item coinNav ">
                <div className="d-flex gap-2">
                  <span className="balance">{remianingBalance}</span>
                  <img src={CoinUp} alt="" />
                </div>
                <span>Resume Balance</span>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link smallCTAsecondary"
                  to="/add-job"
                  onClick={() =>
                    props.onNavigate({ pageHeading: 'Add Job Position' })
                  }
                >
                  Add Job Position
                </NavLink>
              </li>
              <li className="nav-item ml-2">
                <a href="#">
                  <img src={SearchIcon} alt="" />
                </a>
              </li>
              <li className="nav-item ml-2">
                <a href="#">
                  <img src={NotificationIcon} alt="" />
                </a>
              </li>
              <li className="nav-item ml-2">
                <NavLink
                  className="profileNav"
                  to="/profile"
                  onClick={() => props.onNavigate({ pageHeading: 'Profile' })}
                >
                  <img src={ProfileIcon} alt="" />
                  <div>
                    {myProfile?.u_name}
                    <span className="bodyS">{myProfile?.designation}</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.reducer.account || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNavigate: (data) => dispatch(onNavigate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);
