import "../form-style.css";
import OnBoardCoverImg from "../../../../assets/images/onboarding-cover.png";
import GreenTick from "../../../../assets/images/green-tick.svg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { resetPassword } from "container/services/user-services";
import { useMutation } from "@tanstack/react-query";
import { Button, Modal } from "react-bootstrap";
import AlertIcon from "../../../../assets/images/alert_icon.svg";

const SetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("Something went wrong");
  const [show, setShow] = useState({
    modal1: false,
    modal2: false,
  });
  const handleClose = (type) =>
    setShow((prev) => ({
      ...prev,
      modal1: type === "modal1" ? false : show.modal1,
      modal2: type === "modal2" ? false : show.modal2,
      modal3: type === "modal3" ? false : show.modal3,
    }));

  const handleShow = (type) => {
    setShow((prev) => ({
      ...prev,
      modal1: type === "modal1" ? true : show.modal1,
      modal2: type === "modal2" ? true : show.modal2,
      modal3: type === "modal3" ? true : show.modal3,
    }));
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload) => resetPassword(payload),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = inputs;
    if (!password || password !== confirmPassword) {
      toastr.error("Error", "Passwords do not match", {
        position: "top-right",
      });
      return;
    }
    const payload = {
      password,
      token: params.token,
    };

    await mutateAsync(payload, {
      onSuccess: (responseData) => {
        if (responseData?.data?.response_code === -1) {
          setErrorMessage(responseData?.data?.message);
          handleShow("modal2");
          return;
        }
        handleShow("modal1");
      },
      onError: (error) => {
        setErrorMessage(error.message);
        handleShow("modal2");
      },
    });
  };
  return (
    <>
      <div className="onboarding navSep">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 order-md-2">
              <div className="onboardForm">
                <form action="" className="formSet" onSubmit={handleSubmit}>
                  <h2 className="mb-3">Set Password</h2>
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    placeholder="Set a password"
                    value={inputs.password}
                    onChange={(e) => {
                      setInputs((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }));
                    }}
                  />
                  <label htmlFor="">Confirm password</label>
                  <input
                    type="password"
                    placeholder="Confirm the password"
                    value={inputs.confirmPassword}
                    onChange={(e) => {
                      setInputs((prevState) => ({
                        ...prevState,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                  <p className="mt-2 bodyM d-none">
                    <input type="checkbox" id="tandc" name="tandc" />
                    <label htmlFor="tandc">
                      I Agree to the <a href="#">Terms & Conditions</a> and{" "}
                      <a href="#">Privacy Policy</a>
                    </label>
                  </p>
                  <button
                    className="bigCTAprimary w-100 mt-3"
                    disabled={isPending}
                  >
                    {isPending ? "Resetting..." : "Reset Password"}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-7 order-md-1">
              <div className="onboardCover">
                <img src={OnBoardCoverImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show.modal1}
        onHide={() => handleClose("modal1")}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose("modal1")}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: "center" }}>
          <img src={GreenTick} alt="" />
          <p className="bodyM mt-2">The password has been reset successfully</p>
          <div className="d-flex justify-content-center gap-2">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal2}
        onHide={() => handleClose("modal2")}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose("modal2")}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: "center" }}>
          <img src={AlertIcon} alt="" />
          <p className="bodyM mt-2">{errorMessage}</p>
          <div className="d-flex justify-content-center gap-2">
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => {
                handleClose("modal2");
              }}
            >
              ok
            </p>
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SetPassword;
