import "./job-position.css";
import QuestionMark from "../../../../assets/images/question-mark-icon.svg";
import React, { useEffect } from "react";
import { addJobs, fetchTraits } from "../../../services/job-services";
import { useState } from "react";
import GreenTick from "../../../../assets/images/green-tick.svg";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PersonalityImg from "../../../../assets/images/desired-personality-img.jpg";
import AlertIcon from "../../../../assets/images/alert_icon.svg";

const SetDesiredPersonality = (props) => {
  const [traits, setTraits] = useState([]);

  const getTraits = async () => {
    try {
      let t = await fetchTraits();
      t = t.data.map((items) => {
        items.ranges.map((range) => {
          if (range.order_id === 1) selectTrait(range);
          return range.order_id === 1
            ? (range.isSelected = true)
            : (range.isSelected = false);
        });
        return {
          ...items,
          ranges: items.ranges.sort((a, b) => a.order_id - b.order_id),
        };
      });
      t.sort((a, b) => a.order_id - b.order_id);
      setTraits(t);
    } catch (error) {
      console.log(error);
    }
  };

  const [formData, setFormData] = useState({});

  const updateForm = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setFormData({ ...props.filledData });
    getTraits();
  }, []);

  const [selectedTrait, setSelectedTrait] = useState([]);

  const selectTrait = (value) => {
    const tempTraits = [...traits];
    tempTraits.forEach((trait) => {
      if (trait.id === value.trait_id) {
        trait.ranges.map((range) => {
          return range.id === value.id
            ? (range.isSelected = true)
            : (range.isSelected = false);
        });
      }
    });
    setTraits(tempTraits);
    let tempArray = selectedTrait;
    if (
      tempArray.length > 0 &&
      tempArray.findIndex((x) => x.trait_id === value.trait_id) === 0
    )
      tempArray.splice(
        tempArray.findIndex((x) => x.trait_id === value.trait_id),
        1
      );
    tempArray.push(value);
    const traitObject = {};
    tempArray.forEach((item) => {
      traitObject[item.trait_id] = item.id;
    });
    updateForm("traits", JSON.stringify(traitObject));
    setSelectedTrait(tempArray);
  };

  const [routeJobId, setRouteJobId] = useState({});

  const addJob = async () => {
    console.log(formData);
    const fd = new FormData();
    fd.append("traits", formData.traits);
    fd.append("status", "ACTIVE");
    fd.append("title", formData.title);
    fd.append("company_job_id", formData.company_job_id);
    fd.append("department", formData.department);
    fd.append("job_type", formData.job_type);
    fd.append("job_description", formData.job_description);
    fd.append("location", formData.location);
    fd.append("client_name", formData.client_name);
    fd.append("industry_name", formData.industry_name);
    try {
      const job = await addJobs(fd);
      if (job) handleShow("modal1");
      setRouteJobId({ jobId: job.data.id });
      console.log(job);
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState({ modal1: false });
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleClose = (type) =>
    setShow({
      modal1: type === "modal1" ? false : show.modal1,
    });

  const handleShow = (type) =>
    setShow({
      modal1: type === "modal1" ? true : show.modal1,
    });

  const navigate = useNavigate();
  const navigateTo = () => {
    handleClose("modal1");
    navigate(`/job-details/${routeJobId.jobId}`);
  };

  return (
    <>
      <div className="personality-bar-box">
        <h4 className="mt-4">Desired Personality Traits</h4>
        <p>Set the desired level by clicking on the bar </p>
        <div className="w-100 d-flex my-5">
          <img
            src="/images/Big5.png"
            alt="image_pers"
            style={{ width: "400px" }}
          />
        </div>
        <React.Fragment>
          {traits.length === 0 ? (
            <p>Loading</p>
          ) : (
            traits.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="meters" key={item.id}>
                    <strong>{item.name}</strong> ({item.description})
                    <span
                      type="button"
                      id="openness"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={QuestionMark} alt="" className="ml-1" />
                    </span>
                    <div
                      className={`dropdown-menu dropdown-menu-end meterValue p-3 ${
                        index === 0 ? "openness" : ""
                      }`}
                      aria-labelledby="openness"
                    >
                      {item.ranges.map((range, index) => {
                        return (
                          <div>
                            <h6
                              className={
                                range.order_id === 0
                                  ? "alerts"
                                  : range.order_id === 1
                                  ? "acitves"
                                  : "sucessfull"
                              }
                            >
                              {range.name}
                            </h6>
                            {range.keywords.split(",").map((item, index) => {
                              return (
                                <span>
                                  {item}
                                  {index === 0 && <br></br>}
                                </span>
                              );
                            })}
                          </div>
                        );
                      })}
                      {/* <div>
                                                    <h6 className="alerts">Low</h6>
                                                    Cautious<br />
                                                    Confined
                                                </div>
                                                <div>
                                                    <h6 className="actives">Medium</h6>
                                                    Conservative<br />
                                                    Rational
                                                </div>
                                                <div>
                                                    <h6 className="sucessfull">High</h6>
                                                    Creative<br />
                                                    Open-minded
                                                </div> */}
                    </div>
                  </div>
                  <div className="personality-bar-inner-box">
                    {item.ranges.map((range, index) => {
                      return (
                        <div
                          style={{ width: "33.2%" }}
                          key={range.id}
                          onClick={() => selectTrait(range)}
                        >
                          <div
                            className={
                              "personality-bars " +
                              (range.name === "Low" && range.isSelected
                                ? "background-yellow "
                                : range.name === "Medium" && range.isSelected
                                ? "background-blue "
                                : range.name === "High" && range.isSelected
                                ? "background-green "
                                : null) +
                              (index === 0
                                ? "br-left "
                                : index === 2
                                ? "br-right "
                                : null)
                            }
                          ></div>
                          {range.isSelected ? (
                            <span className="smallTextRangeTitle">
                              <span
                                className={
                                  range.name === "Low"
                                    ? "alerts"
                                    : range.name === "Medium"
                                    ? "actives"
                                    : range.name === "High"
                                    ? "sucessfull"
                                    : null
                                }
                                style={{ marginRight: "5px", fontWeight: 700 }}
                              >
                                {range.name}
                              </span>
                              {range.keywords}
                            </span>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })
          )}
        </React.Fragment>
        <button
          type="submit"
          className="smallCTAprimary mt-4"
          onClick={() => {
            setIsShowConfirmModal(true);
          }}
        >
          Add This Job Position
        </button>
      </div>

      {/* Modal Upload Success */}
      <Modal
        show={show.modal1}
        onHide={() => handleClose("modal1")}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose("modal1")}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: "center" }}>
          <img src={GreenTick} alt="" />
          <p className="smallBold BodyM mt-2">
            Job Position added successfully
          </p>
          <button
            type="submit"
            className="smallCTAprimary mt-4"
            onClick={() => {
              navigateTo();
            }}
          >
            Ok
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={isShowConfirmModal}
        onHide={() => {
          setIsShowConfirmModal(false);
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setIsShowConfirmModal(false);
            }}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src={AlertIcon} alt="" />
          </p>
          <h3>Are you sure?</h3>
          <div className="dualCTA">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => {
                setIsShowConfirmModal(false);
              }}
            >
              No
            </p>
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => {
                addJob();
                setIsShowConfirmModal(false);
              }}
            >
              Yes
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SetDesiredPersonality;
