import { useQuery } from '@tanstack/react-query';

import { getUserBalance } from 'container/services/user-services';

import { TIME } from './constants';

export const useGetBalance = () =>
  useQuery({
    queryKey: ['balance'],
    queryFn: getUserBalance,
    refetchInterval: TIME,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: TIME,
    gcTime: TIME,
  });
