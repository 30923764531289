import React, { useState } from 'react';
import { useGetBalance } from 'hooks/useGetBalance';
import { Button, Modal } from 'react-bootstrap';
import { CiEdit } from 'react-icons/ci';
import { useQueryClient } from '@tanstack/react-query';
import { useProfileUpdate } from 'hooks/useProfileUpdate';

const CompanyProfile = ({ companyData, isAdmin }) => {
  const queryClient = useQueryClient();

  const { data } = useGetBalance();
  const [isEditing, setIsEditing] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(() => ({
    poc_designation: companyData?.poc_designation || '',
    address: companyData?.address || '',
    phone_number: companyData?.phone_number || '',
    phone_number_code: companyData?.phone_number_code || '',
  }));
  const updateProfileData = useProfileUpdate(queryClient);
  const [isShowAccessKey, setIsShowAccessKey] = useState(false);

  const handleEdit = () => {
    setIsEditing((prevState) => !prevState);
  };

  const handleSave = async () => {
    await updateProfileData.mutateAsync({
      company_profile: companyProfile,
    });
    setIsEditing(false);
  };

  if (!companyData) return null;

  const remianingBalance =
    (data?.data?.data?.total_credits || 0) -
    (data?.data?.data?.credits_used || 0);

  return (
    <>
      <div
        className="tab-pane fade"
        id="nav-c-profile"
        role="tabpanel"
        aria-labelledby="nav-c-profile-tab"
        tabindex="0"
      >
        <div className="row justify-content-end mt-3">
          <div className="col-2">
            {isAdmin && (
              <Button variant="outline-primary" onClick={handleEdit}>
                Edit <CiEdit size={16} />
              </Button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <form
              action=""
              className="formSet mt-4"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label htmlFor="">Company name</label>
              <p>{companyData?.c_name}</p>
              <label htmlFor="">Point of contact name</label>
              <p>{companyData?.poc_name}</p>
              <label htmlFor="">Point of contact Designation</label>
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={companyProfile?.poc_designation}
                    onChange={(e) => {
                      setCompanyProfile((prevState) => ({
                        ...prevState,
                        poc_designation: e.target.value,
                      }));
                    }}
                  />
                </>
              ) : (
                <p>{companyData?.poc_designation}</p>
              )}

              <label htmlFor="">Point of contact official email id</label>
              <p>{companyData?.poc_email}</p>
              <label htmlFor="">Point of Contact Mobile</label>
              {isEditing ? (
                <div className="d-flex">
                  <input
                    type="text"
                    value={companyProfile?.phone_number_code}
                    onChange={(e) => {
                      setCompanyProfile((prevState) => ({
                        ...prevState,
                        phone_number_code: e.target.value,
                      }));
                    }}
                    style={{ width: '80px' }}
                  />
                  <input
                    className="flex-grow-1"
                    type="text"
                    value={companyProfile?.phone_number}
                    onChange={(e) => {
                      setCompanyProfile((prevState) => ({
                        ...prevState,
                        phone_number: e.target.value,
                      }));
                    }}
                  />
                </div>
              ) : (
                <p>
                  {companyData?.phone_number_code}
                  {companyData?.phone_number}
                </p>
              )}
              <button
                className={`bigCTAprimary w-100 mt-3 ${
                  !isEditing || updateProfileData.isPending ? 'disables' : ''
                }`}
                onClick={handleSave}
              >
                {updateProfileData.isPending ? 'Saving...' : 'Save'}
              </button>
            </form>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-4">
            <div className="companyDetails mt-5">
              <h5>Company Details</h5>
              <h2 className="mb-3">{companyData?.c_name}</h2>
              {!!companyData.company_access_key && (
                <p
                  className="tertiaryBtn d-inline cursorP"
                  onClick={() => {
                    setIsShowAccessKey(true);
                  }}
                >
                  Company Access Key
                </p>
              )}
              <div className="columnRow mt-4">
                <h5>Address</h5>
              </div>
              {isEditing ? (
                <textarea
                  type="text"
                  value={companyProfile?.address}
                  className="w-full"
                  rows={4}
                  onChange={(e) => {
                    setCompanyProfile((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                />
              ) : (
                <p>{companyData?.address || ''}</p>
              )}
            </div>

            <div className="balanceCard mt-4">
              <h5>Balance Credits</h5>
              <div className="columnRow">
                <span className="coinNumber">
                  <img src="images/yello-coin.svg" alt="" /> {remianingBalance}
                </span>
                <span>
                  <img src="images/coinUp.svg" alt="" /> {remianingBalance}{' '}
                  Resume Balance
                </span>
              </div>
            </div>
            <p className="mt-3 text-right">
              <a href="#" className="tertiaryBtn">
                Buy Credits
              </a>
            </p>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <Modal
        show={isShowAccessKey}
        onHide={() => setIsShowAccessKey(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsShowAccessKey(false)}
          ></button>
        </div>
        <Modal.Body>
          <h3 className="text-center">Company Access key</h3>
          <h3 className="text-center">{companyData.company_access_key}</h3>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompanyProfile;
