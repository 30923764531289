import '../form-style.css';
import OnBoardCoverImg from '../../../../assets/images/onboarding-cover.png';
import api from '../../../../config/backend-url';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { NavLink, useNavigate } from 'react-router-dom';
import { getForgotPassword, userLogin } from '../../../services/auth-services';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { onLogin } from '../../../store/action';

const Login = (props) => {
    const navigate = useNavigate();

    const [show, setShow] = useState({ modal1: false, modal2: false });

    const handleClose = (type) => setShow(prevState => ({
        ...prevState,
        modal1: type === 'modal1' ? false : show.modal1,
        modal2: type === 'modal2' ? false : show.modal2,
    }));

    const handleShow = (type) => setShow(prevState => ({
        ...prevState,
        modal1: type === 'modal1' ? true : show.modal1,
        modal2: type === 'modal2' ? true : show.modal2,
    }));

    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});

    const [emailError, setEmailError] = useState(false);

    const updateForm = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    }

    const [submitted, setSubmitted] = useState(false);

    const onLogin = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            try {
                const login = await userLogin(formData);
                if (login) {
                    setValidated(false);
                    const account = { ...login, isLoggedIn: true }
                    props.onLoggedIn(account)
                    redirectToDashboard()
                }
            } catch (error) {
                toastr.error(error.response.data);
            }
        }
    }

    const forgotPassword = async () => {
        setSubmitted(true);
        try {
            const fp = await getForgotPassword(formData.email);
            if (fp) {
                handleShow('modal2');
                handleClose('modal1')
                setEmailError(false)
            }
        } catch (error) {
            toastr.error(error)
            setEmailError(true)
        }
    }

    const redirectToDashboard = () => {
        navigate('/dashboard');
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-4 order-md-2">
                        <div className="onboardForm">
                            <Form noValidate validated={validated} onSubmit={onLogin} className="formSet">
                                <h2 className="mb-3">Log in</h2>
                                <Form.Group>
                                    <Form.Label>Official email Id</Form.Label>
                                    <Form.Control type="text" required placeholder="Official email Id" onChange={e => { updateForm('username', e.target.value) }} />

                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" required placeholder="Password" onChange={e => { updateForm('password', e.target.value) }} />

                                </Form.Group>
                                <p className="mt-2 text-right"><a href="#" className="xsCTA" onClick={() => { handleShow('modal1') }}>Forgot password?</a></p>
                                <button type='submit' className='bigCTAprimary w-100 mt-3'>Log In</button>
                                <p className="mt-5 text-center xsCTA">Don’t have an account? <NavLink to="/signup">Sign up</NavLink></p>
                            </Form>
                        </div>
                    </div>
                    <div className="col-md-7 order-md-1">
                        <div className="onboardCover">
                            <img src={OnBoardCoverImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show.modal1}
                onHide={() => handleClose('modal1')}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className='w-100 btn-close-flex'>
                    <button type="button" className="btn-close" onClick={() => handleClose('modal1')}></button>
                </div>
                <Modal.Body>
                    <h3>Forgot password?</h3>
                    <p className="bodyM">Don’t worry! It happens. Please enter the email id associated with your account.</p>
                    <form action="" className="formSet">
                        <label htmlFor="">Official email Id</label>
                        <input type="text" placeholder="Email Id" onChange={e => updateForm('email', e.target.value)} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {
                                emailError ? <span className='bodyM danger'>No account associated with this email Id</span> : <></>
                            }
                        </div>
                        <button type='button' className="bigCTAprimary w-100 mt-3" onClick={forgotPassword}>Reset Password</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={show.modal2}
                onHide={() => handleClose('modal2')}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='sm'
            >
                <div className='w-100 btn-close-flex'>
                    <button type="button" className="btn-close" onClick={() => handleClose('modal2')}></button>
                </div>
                <Modal.Body>
                    <h3>Check your email</h3>
                    <p className="bodyM">Instructions on how to reset your password were sent to your email</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoggedIn: (data) => dispatch(onLogin(data)),
    }
}

export default connect('', mapDispatchToProps)(Login);