import React from "react";

const dummyInvoices = [];

const Billing = () => {
  return (
    <div
      className="tab-pane fade"
      id="nav-billing"
      role="tabpanel"
      aria-labelledby="nav-billing-tab"
      tabindex="0"
    >
      <div className="stickyTop mt-4">
        <div className="row">
          <div className="col-md-5 cardControls">
            {!!dummyInvoices.length && <h5>{dummyInvoices.length} Invoices</h5>}
          </div>
          <div className="col-md-7 cardControls text-right">
            {!!dummyInvoices.length && (
              <a href="#" className="smallCTAprimary">
                Download
              </a>
            )}
          </div>
        </div>
      </div>
      <table className="table resumeTable">
        <thead>
          <tr>
            <th scope="col">
              <input type="checkbox" />
            </th>
            <th scope="col">Invoice</th>
            <th scope="col">Billing Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Credits</th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          {dummyInvoices.map((invoice) => (
            <tr key={invoice.id}>
              <th scope="row">
                <input type="checkbox" />
              </th>
              <td>
                <img src="images/pdf.svg" alt="" /> {invoice.invoice}
              </td>
              <td>{invoice.date}</td>
              <td>{invoice.amount}</td>
              <td>{invoice.credits}</td>
              <td className="text-right">
                <a href="#" className="smallCTAsecondary">
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Billing;
