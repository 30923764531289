import { useQueryClient } from '@tanstack/react-query';
import { useProfileUpdate } from 'hooks/useProfileUpdate';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CiEdit } from 'react-icons/ci';

const MyProfile = ({ userData }) => {
  const queryClient = useQueryClient();

  const updateProfileData = useProfileUpdate(queryClient);

  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing((prevState) => !prevState);
  };

  const [designation, setDesignation] = useState(
    () => userData.designation || ''
  );

  const onSubmit = async () => {
    await updateProfileData.mutateAsync({
      my_profile: {
        designation,
      },
    });
    setIsEditing(false);
  };

  if (!userData) return null;

  return (
    <div
      className="tab-pane fade show active"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
      tabindex="0"
    >
      <div className="row">
        <div className="col-md-4">
          <div className="d-flex mt-4 justify-content-end">
            <Button variant="outline-primary" onClick={handleEdit}>
              Edit <CiEdit size={16} />
            </Button>
          </div>
          <form
            action=""
            className="formSet mt-4"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label htmlFor="">Name</label>
            <p>{userData.u_name}</p>
            <label htmlFor="">Designation</label>
            {isEditing ? (
              <input
                type="text"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                className="border"
              />
            ) : (
              <p>{userData.designation}</p>
            )}

            <label htmlFor="">Official email id</label>
            <p>{userData.email}</p>
            <button
              className={`bigCTAprimary w-100 mt-3 ${
                !designation || !isEditing || updateProfileData.isPending
                  ? 'disables'
                  : ''
              }`}
              onClick={onSubmit}
            >
              {updateProfileData.isPending ? 'Saving...' : 'Save'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
