import { useMutation } from '@tanstack/react-query';

import { profileUpdate } from 'container/services/user-services';

export const useProfileUpdate = (queryClient) =>
  useMutation({
    mutationFn: (payload) => profileUpdate(payload),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile_details'],
      });
    },
  });
