import React from 'react';

// import './recruiter.css';

const Recruiter = () => {
  return (
    <main className="c2 doc-content text-left">
      <p className="c1">
        <span className="c3">From : </span>
        <span className="c7">support</span>
        <span className="c7 c8">@fairee.com.sg</span>
      </p>
      <p className="c1">
        <span className="c0">To : &lt;&lt;Applicant Email&gt;&gt;</span>
      </p>
      <p className="c1">
        <span className="c0">Cc : &lt;&lt;user email&gt;&gt;</span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Email Subject: Your resume has been shortlisted for the position of
          &lt;&lt;Job Position&gt;&gt;
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">Dear &lt;&lt;Applicant Name&gt;&gt;,</span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          You are receiving this email via Fairee Technologies Pte Ltd on behalf
          of &lt;&lt;Company Name&gt;&gt;, a recruitment services provider.
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          Currently we are looking for a suitable candidate for the position of
          &lt;&lt;Job Position&gt;&gt;, at one of our client organisations, in
          the{' '}
        </span>
        <span className="c7">&lt;&lt;industry&gt;&gt;</span>
        <span className="c0">&nbsp;sector. </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          We have reviewed your resume and we are interested to follow up with
          you for the next stage of our recruitment process. You are requested
          to upload a short 4-5 minute self-introduction video for us to know
          you better.
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Please note that the video introduction does not replace human
          intervention with any automated decisions. Shortlist review and
          selection decisions of the candidate video will be made by the hiring
          manager and/or the recruiter.
        </span>
      </p>
      <p className="c6">
        <span className="c0">
          Kindly introduce yourself and answer the following question in your
          video:
        </span>
      </p>
      <p className="c1">
        <span className="c9">
          Q. In what ways do you believe that your professional background and
          skill set align with the requirements of the position mentioned above?
        </span>
        <span className="c0">&nbsp;</span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Please record your video with clear audio and in a well-lit
          environment (with your front face clearly visible), on your desktop,
          laptop or mobile/tablet, and follow the instructions as mentioned
          below to upload the video by the submission deadline. Only the videos
          sent via the upload link will be accepted. The upload site is managed
          by our recruitment services partner, Fairee Technologies Pte Ltd.
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Video Upload Link : &lt;&lt;Personalized Video Link&gt;&gt;
        </span>
      </p>
      <p className="c1">
        <span className="c3">Submission Deadline : &lt;&lt;Date </span>
        <span className="c7 c8">(5 calendar days)&gt;&gt;</span>
      </p>
      <p className="c1">
        <span className="c0">Video Duration : 4-5 minutes</span>
      </p>
      <p className="c1">
        <span className="c0">Video Size Limit : 150 MB </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          We wish you all the best for the next stage of this journey. We will
          contact you if you are selected for the subsequent stages of this
          recruitment process.
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">
          Please do not hesitate to contact us by replying to this email only if
          you face any technical difficulties in uploading your video.
        </span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">Best regards,</span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c0">&lt;&lt;Name of User&gt;&gt;</span>
      </p>
      <p className="c1">
        <span className="c0">&lt;&lt;Company Name&gt;&gt;</span>
      </p>
      <p className="c1 c4">
        <span className="c0"></span>
      </p>
      <p className="c1">
        <span className="c3">
          Note: This is a system generated email. Inadvertent errors and
          omissions due to system issues are regretted. If you are not the
          intended recipient of this email, please let us know at
          support@fairee.com.sg.
        </span>
      </p>
    </main>
  );
};

export default Recruiter;
