import { NavLink, useNavigate } from "react-router-dom";
import "./sidebar.css";
import HelpCircle from "../../../../assets/images/help-circle.svg";
import Logout from "../../../../assets/images/log-out-01.svg";
import Logo from "../../../../assets/images/logo_fairee_dashboard.png";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import { userLogout } from "../../../services/auth-services";
import { connect } from "react-redux";
import { onLoggout, onNavigate } from "../../../store/action";
import { useQueryClient } from "@tanstack/react-query";
import { getSignedUrl } from "container/services/job-services";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [show, setShow] = useState({ modal1: false });
  const [helpVideo, setHelpVideo] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const handleClose = (type) =>
    setShow({
      modal1: type === "modal1" ? false : show.modal1,
    });

  const handleShow = (type) =>
    setShow({
      modal1: type === "modal1" ? true : show.modal1,
    });

  const onLogout = async () => {
    await userLogout();
    props.onLoggedOut();
    handleClose("modal1");
    navigate("/login");
    queryClient.clear();
  };

  const handleHelp = async () => {
    setIsGenerating(true);
    const response = await getSignedUrl("promo_video/Training Video Final.mp4");
    if (response.success) {
      setHelpVideo(response.singedUrl);
    } else {
      Alert("Error", "Error in getting help video", "error");
    }
    setIsGenerating(false);
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <div className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none mt-4">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="" />
          </a>
        </div>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 mt-4 align-items-center align-items-sm-start w-100"
          id="menu"
        >
          <li className="nav-item mt-2">
            <NavLink
              to="/dashboard"
              className="sideNavItems dashBoard"
              onClick={() => props.onNavigate({ pageHeading: "Dashboard" })}
            >
              Dashboard
            </NavLink>
          </li>

          <li className="nav-item mt-2">
            <NavLink
              to="/job-dashboard"
              className="sideNavItems jobposition"
              onClick={() => props.onNavigate({ pageHeading: "Job Positions" })}
            >
              Job Positions
            </NavLink>
          </li>

          <li className="nav-item mt-2">
            <a
              href="#"
              className="sideNavItems credits"
              onClick={() => props.onNavigate({ pageHeading: "Buy Credits" })}
            >
              Buy Credits
            </a>
          </li>
        </ul>
        <hr />
        <div>
          <ul className="navBottomLinks">
            <li onClick={handleHelp}>
              <a className="cursorP">
                <img src={HelpCircle} alt="" /> How it Works
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleShow("modal1")}>
                <img src={Logout} alt="" /> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        show={show.modal1}
        onHide={() => handleClose("modal1")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose("modal1")}
          ></button>
        </div>
        <Modal.Body>
          <h3 className="text-center">Do you want to logout?</h3>
          <div className="dualCTA">
            <button
              className="smallCTAsecondary w-50 mt-3"
              onClick={() => onLogout()}
            >
              Yes
            </button>
            <button
              className="bigCTAprimary w-50 mt-3"
              onClick={() => handleClose("modal1")}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isGenerating || helpVideo !== ""}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setHelpVideo("");
        }}
        dialogClassName="w-full"
      >
        <Modal.Body>
          {isGenerating ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              <div className="row mt-3">
                <video controls controlsList="nodownload">
                  <source src={helpVideo} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoggedOut: () => dispatch(onLoggout()),
    onNavigate: (data) => dispatch(onNavigate(data)),
  };
};

export default connect("", mapDispatchToProps)(Sidebar);
