import { useQuery } from '@tanstack/react-query';

import { getVideoUploaderData } from 'container/services/user-services';

import { TIME } from './constants';

export const useGetVideoUploader = (videoTokenId) =>
  useQuery({
    queryKey: ['video_uploader'],
    queryFn: () => getVideoUploaderData(videoTokenId),
    refetchInterval: TIME,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: TIME,
    gcTime: TIME,
  });
