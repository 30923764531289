import api from '../../config/backend-url';

export const inviteCandidateVideo = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post('candidate/invite_for_intro_video', data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCanditateData = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post('candidate/update_info', data)
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadCanditateVideo = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post('candidate/upload_intro_video', data, {})
      .then((res) => {
        if (res.data.response_code === -1) {
          reject(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
