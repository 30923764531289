export const passwordChecker = (value) => {
    // const regext = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    let tempArr = [];
    if (!value.match(/^(?=.*[a-z])/)) {
        tempArr.push({ message: 'Atleast 1 lower case letter' })
    }
    if (!value.match(/^(?=.*[A-Z])/)) {
        tempArr.push({ message: 'Atleast 1 upper case letter' })
    }
    if (!value.match(/^.{8,}$/)) {
        tempArr.push({ message: 'Atleast 8 letters' })
    }
    if (!value.match(/[@#]+/)) {
        tempArr.push({ message: 'Atleast 1 special character' })
    }
    if (!value.match(/^(?=.*\d)/)) {
        tempArr.push({ message: 'Atleast 1 number' })
    }
    return tempArr;
}

export const emailChecker = (value, type) => {
    let tempArr = [];
    const regex = type === 'auth' ? /^[\w-.]+@(?!gmail)(?!hotmail)(?!yahoo)(?!outlook)(?!aol)(?!proton)(?!zoho)(?!gmx)(?!icloud)(?!mail2world)(?!tutanota)(?!juno).*[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,6})$/
        : /^[\w-.]+@(?!hotmail)(?!yahoo)(?!outlook)(?!aol)(?!proton)(?!zoho)(?!gmx)(?!icloud)(?!mail2world)(?!tutanota)(?!juno).*[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,6})$/;
    if (!value.match(regex)) {
        tempArr.push({ message: 'Please enter a valid email id' })
    }
    return tempArr;
}
// /^([\w-.]+@(?!gmail)(?!yahoo)(?!hotmail).*([\w-]+.)+[\w-]{2,4})?$/

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
export const phoneNumberChecker = (value, countryCode) => {
    console.log({ value }, { countryCode });
    // const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
    var regex = new RegExp(/^[+]{1}(?:[0-9()/.]\s?){6,15}[0-9]{1}$/)
    // const number = phoneUtil.parseAndKeepRawInput('202-456-1414', 'US');

    // console.log({ number });
    let tempArr = [];
    if (regex.test(value) === false) {
        console.log(regex.test(value));
        tempArr.push({ message: 'Invalid Phone Number' })
    } else {
        console.log(regex.test(value));
    }
    return tempArr;
} 