import {
  NavLink,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "./job-position.css";
import React, { useEffect, useRef, useState } from "react";
import {
  fetchAllowedFileTypes,
  fetchJobByID,
  fetchTraits,
  getBlobData,
  getSignedUrl,
  getTextData,
  permanentDelete,
  updateJobInfo,
  uploadResumeAPI,
} from "../../../services/job-services";
import { Modal } from "react-bootstrap";
import AlertIcon from "../../../../assets/images/alert_icon.svg";
import GreenTick from "../../../../assets/images/green-tick.svg";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FiExternalLink } from "react-icons/fi";
import { FaCopy } from "react-icons/fa";
import { IoIosDoneAll } from "react-icons/io";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import FileViewer from "container/components/Common/FileViewer";
import api from "config/backend-url";
import { useGetBalance } from "hooks/useGetBalance";
import Resume from "./Resume";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const JobDescription = (props) => {
  const navigation = useNavigate();

  const [show, setShow] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [completedPerc, setCompletedPerc] = useState(0);
  const { data: balanceData } = useGetBalance();
  const [selectedResume, setSelectedResume] = useState(null);
  const [errorData, setErrorData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleModal = (state, type) => {
    setShow((prevState) => ({
      ...prevState,
      [state]: type === "close" ? false : true,
    }));
    clearAllErrors();
  };

  const [traits, setTraits] = useState([]);

  const getTraits = async (jbt) => {
    try {
      let t_data = await fetchTraits();
      let t = t_data.data;
      for (let i = 0; i < t.length; i++) {
        for (let k = 0; k < t[i].ranges.length; k++) {
          t[i].ranges[k].isSelected = false;
          for (let y = 0; y < jbt.length; y++) {
            if (jbt[y].trait_range.trait_id === t[i].ranges[k].trait_id) {
              if (jbt[y].trait_range.id === t[i].ranges[k].id) {
                t[i].ranges[k].isSelected = true;
              }
            }
          }
        }
        t[i].ranges.sort((a, b) => a.order_id - b.order_id);
      }
      t.sort((a, b) => a.order_id - b.order_id);
      setTraits(t);
    } catch (error) {
      console.log(error);
    }
  };
  const [job, setJob] = useState({});

  const [fileTypes, setFileTypes] = useState({});
  useEffect(() => {
    setJob({ ...props.jobs });
    if (props && props.jobs) getTraits(props.jobs.traits);
    if (props && props.fileType) setFileTypes({ ...props.fileType });
  }, [props]);

  const [fileError, setFileError] = useState({ isError: false, message: "" });
  const [file, setFile] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const [progressBar, setProgessBar] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const [notSupportedFiles, setNotSupportedFiles] = useState([]);
  const remianingBalance =
    (balanceData?.data?.data?.total_credits || 0) -
    (balanceData?.data?.data?.credits_used || 0);

  const uploadMultipleFiles = async () => {
    const totalAttachedResumes = file.length;
    if (totalAttachedResumes > remianingBalance) {
      setFileError({
        isError: true,
        message: `You do not have sufficient credits to upload ${totalAttachedResumes} number of resumes`,
      });
      return;
    }

    if (file.length === 0) {
      setFileError({ isError: true, message: "Please choose at-least 1 file" });
    } else {
      let supportFiles = [];
      let notSupportedTempFileArray = [];
      file.forEach((file) => {
        let tempError = false;
        if (
          props.fileType &&
          props.fileType.filter_mimetype.includes(file.type)
        ) {
          if (file.size <= 50 * 1000 * 1000) {
            supportFiles.push(file);
          } else {
            tempError = true;
            file.error = "Large File Size";
          }
        } else {
          file.error = "File Type not supported";
          tempError = true;
        }
        if (tempError) {
          notSupportedTempFileArray.push({
            name: file.name,
            error: file.error,
          });
          setFileError({
            isError: true,
            message: "Some File types are not supported.",
          });
        }
      });
      if (supportFiles.length > 0) clearAllErrors();
      setNotSupportedFiles(notSupportedTempFileArray);
      toggleModal("modal1", "close");
      toggleModal("modal3", "open");

      const fd = new FormData();
      supportFiles.forEach((item) => {
        fd.append("resumes", item);
      });
      fd.append("job_id", props.job_id);
      try {
        setIsUploading(true);
        setIsProcessing(true);
        const config = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setCompletedPerc(percentCompleted);
            if (percentCompleted === 100) {
              setIsUploading(false);
            }
          },
        };
        const { data: upload } = await api.post(
          "job/upload_resumes",
          fd,
          config
        );

        console.log("upload", upload);
        // setIsUploading(false);
        let tempArry = [];
        Object.keys(upload.data.files_filtered).map((item) => {
          upload.data.files_filtered[item].map((file) => {
            tempArry.push({
              name: file,
              error:
                item === "already_present"
                  ? "File Already Present"
                  : item === "duplicates"
                  ? "Duplicate File"
                  : item === "extension"
                  ? "File Type not allowed"
                  : item === "page_limit"
                  ? "Page Limit Exceeded"
                  : null,
            });
          });
        });
        setErrorData(tempArry);
        const dummy = [...notSupportedTempFileArray, ...tempArry];
        const filtered = dummy.filter(
          (a, b) => dummy.findIndex((x) => x.name === a.name) === b
        );

        let tempFilterArray = [...supportFiles];
        supportFiles.forEach((sfile, index) => {
          filtered.forEach((fFile) => {
            if (sfile.name === fFile.name) tempFilterArray.splice(index, 1);
          });
        });

        if (file.length === filtered.length) {
          setFile([]);
        } else {
          console.log("supportFiles", supportFiles);
          setFile(supportFiles);
        }
        setNotSupportedFiles(filtered);
        // props.refreshJob(true);
        // clearAllErrors();
      } catch (error) {
        console.log(error);
      } finally {
        setIsProcessing(false);
        // setIsUploading(false);
        // toggleModal('modal4', 'open');
      }
    }
  };

  const clearAllErrors = () => {
    setFileError({ isError: false });
  };

  const clearFiles = () => {
    setFile([]);
  };

  const closeJob = async () => {
    const status = job.candidates.length === 0 ? "ARCHIVED" : "CLOSED";
    try {
      const data = {
        job_id: props.job_id,
        status,
      };
      const update = await updateJobInfo(data);
      toggleModal("modal2", "close");
      navigation(`/job-dashboard`);
      console.log({ update });
    } catch (error) {
      toastr.error(error);
      toggleModal("modal2", "close");
    }
  };

  const handleDelete = async () => {
    try {
      const data = {
        job_id: props.job_id,
      };
      const update = await permanentDelete(data);
      toggleModal("modal6", "close");
      navigation(`/job-dashboard`);
      console.log({ update });
    } catch (error) {
      toastr.error(error);
      toggleModal("modal6", "close");
    }
  };

  const handleCopy = async () => {
    try {
      const jsonString = JSON.stringify(notSupportedFiles, undefined, 2); // Pretty print for readability
      await navigator.clipboard.writeText(jsonString);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Clear copied state after 3 seconds
    } catch (error) {
      console.error("Error copying data:", error);
    }
  };

  // "DRAFT", "ACTIVE", "ARCHIVED", "CLOSED"

  console.log("myJob", job);

  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-md-4">
            <div className="jobCards mVgap">
              <div className="department m-3 mb-0 smallSubT">
                {job.department}
                <span className="actives">ID - {job.company_job_id}</span>
              </div>
              <h5 className="m-3 mt-0 mb-0">{job.title}</h5>
              <div className="department jobPosition m-3 mt-1">
                <span>
                  {job.location || "Location"} • {job.job_type}
                </span>
                <span>
                  User:{" "}
                  <strong>
                    {job && job.created_by && job.created_by.name}
                  </strong>
                </span>
              </div>
              <div className="m-3">
                <button
                  className={
                    "smallCTAsecondary d-block w-100 " +
                    (job.status === "CLOSED" ||
                    job.status === "ARCHIVED" ||
                    Object.keys(job).length === 0
                      ? "disables"
                      : "")
                  }
                  onClick={() => {
                    toggleModal("modal1", "open");
                    clearFiles();
                  }}
                  disabled={
                    job.status === "CLOSED" ||
                    job.status === "ARCHIVED" ||
                    Object.keys(job).length === 0
                  }
                >
                  Add / Upload Resumes
                </button>
              </div>
              <p className="helpText text-center m-3">
                You can upload folder here, resume file format
                {Object.values(fileTypes).length > 0 &&
                  fileTypes.filter_extension.map((item) => {
                    return " " + item;
                  })}
              </p>
              <div className="m-3">
                <button
                  className={
                    "smallCTAdanger d-block w-100 " +
                    (job.status === "ARCHIVED" || Object.keys(job).length === 0
                      ? "disables"
                      : "")
                  }
                  onClick={() =>
                    job.status === "CLOSED"
                      ? toggleModal("modal6", "open")
                      : toggleModal("modal2", "open")
                  }
                  disabled={
                    job.status === "ARCHIVED" ||
                    Object.keys(job).length === 0 ||
                    isDeleting
                  }
                >
                  {job.status === "CLOSED" ? (
                    `${
                      isDeleting
                        ? "Deleting..."
                        : "Delete all data associated with this job"
                    }`
                  ) : (
                    <>
                      {job && job.candidates && job.candidates.length === 0
                        ? "Delete"
                        : "Close"}{" "}
                      This Job Position
                    </>
                  )}
                </button>
              </div>
            </div>
            <hr />
            <div className="jobTraits">
              <p className="subTitle">Desired Personality Traits </p>
              <React.Fragment>
                {traits.length === 0 ? (
                  <p>Loading</p>
                ) : (
                  traits.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="meters" key={item.id}>
                          <strong>{item.name}</strong>
                        </div>
                        <div className="personality-bar-inner-box">
                          {item.ranges.map((range, index) => {
                            return (
                              <div style={{ width: "33%" }} key={range.id}>
                                <div
                                  className={
                                    "personality-bars cstm-height " +
                                    (range.name === "Low" && range.isSelected
                                      ? "background-yellow "
                                      : range.name === "Medium" &&
                                        range.isSelected
                                      ? "background-blue "
                                      : range.name === "High" &&
                                        range.isSelected
                                      ? "background-green "
                                      : null) +
                                    (index === 0
                                      ? "br-left "
                                      : index === 2
                                      ? "br-right "
                                      : null)
                                  }
                                ></div>
                                {range.isSelected ? (
                                  <span className="smallTextRangeTitle">
                                    <span
                                      className={
                                        range.name === "Low"
                                          ? "alerts"
                                          : range.name === "Medium"
                                          ? "actives"
                                          : range.name === "High"
                                          ? "sucessfull"
                                          : null
                                      }
                                      style={{
                                        marginRight: "5px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {range.name}
                                    </span>
                                    {/* {range.keywords} */}
                                  </span>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-8">
            <div className="jobDiscription mVgap">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h6 className="m-0">Job Description:</h6>
                <p
                  className="m-0"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  onClick={() => {
                    setSelectedResume(props?.jobs?.job_description);
                    toggleModal("modal5", "open");
                  }}
                >
                  <FiExternalLink color="blue" />
                  <span
                    style={{
                      color: "blue",
                    }}
                  >
                    Open file in a separate window
                  </span>
                </p>
              </div>
              <div>
                {!!props?.jobs?.job_description?.bucket_key && (
                  <FileViewer
                    bucketKey={props?.jobs?.job_description?.bucket_key}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show.modal1}
        onHide={() => toggleModal("modal1", "close")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              toggleModal("modal1", "close");
              clearAllErrors();
              clearFiles();
            }}
          ></button>
        </div>
        <Modal.Body>
          {isUploading ? (
            <div>
              <p className="text-center">Uploading Resumes</p>
              <div className="d-flex justify-content-center mt-4">
                <div style={{ width: 150, height: 150, textAlign: "center" }}>
                  <CircularProgressbar
                    value={completedPerc}
                    text={`${completedPerc}%`}
                    styles={buildStyles({
                      pathColor: "#437AF8",
                      backgroundColor: "#437AF8",
                      textColor: "#437AF8",
                    })}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <h5 className="text-center">Add / Upload Resumes</h5>
              <div className="inputSet uploadFiles">
                {/* <input
                            type="file"
                            placeholder="Drag the folder here or click to add"
                            multiple
                            onChange={(file) => checkFile(file)}
                            accept={Object.keys(fileTypes).length > 0 && fileTypes.filter_mimetype.toString()}
                        /> */}
                <div {...getRootProps()}>
                  <input
                    {...getInputProps()}
                    multiple
                    accept={
                      Object.keys(fileTypes).length > 0 &&
                      fileTypes.filter_mimetype.toString()
                    }
                  />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p className="cursorP">
                      Click <span className="actives cursorP">here </span>
                      to add file(s)
                    </p>
                  )}
                </div>
              </div>
              <p className="helpText text-center m-3">
                You can upload folder here, resume file format
                {Object.values(fileTypes).length > 0 &&
                  fileTypes.filter_extension.map((item) => {
                    return " " + item;
                  })}
              </p>
              {fileError.isError ? (
                <div className="error-toaster">{fileError.message}</div>
              ) : null}

              {file.length > 0 && (
                <div className="file-upload-progess-list">
                  <p className="mb-1">
                    Selected Files: <b>{file.length}</b>
                  </p>
                  {file.map((item, index) => {
                    return (
                      <p className=" bodyM mb-1" key={item.name}>
                        {index + 1 + ": "}
                        {item.name}
                      </p>
                    );
                  })}
                </div>
              )}
              <p
                className="bigCTAprimary mt-2"
                onClick={() => uploadMultipleFiles()}
              >
                Upload
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={show.modal2}
        onHide={() => toggleModal("modal2", "close")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal("modal2", "close")}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src={AlertIcon} alt="" />
          </p>
          <h3>Are you sure!</h3>
          <p>
            You want to{" "}
            {job && job.candidates && job.candidates.length === 0
              ? "delete"
              : "close"}{" "}
            this job position?
          </p>
          <div className="dualCTA">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => toggleModal("modal2", "close")}
            >
              No
            </p>
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => closeJob()}
            >
              Yes
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show.modal6}
        onHide={() => toggleModal("modal6", "close")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal("modal6", "close")}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <p>
            <img src={AlertIcon} alt="" />
          </p>
          <h3>Are you sure! This is non-reversible</h3>
          <div className="dualCTA">
            <p
              className="bigCTAprimary w-50 mt-3"
              onClick={() => toggleModal("modal6", "close")}
            >
              No
            </p>
            <p
              className="smallCTAsecondary w-50 mt-3 cursorP"
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show.modal3}
        onHide={() => toggleModal("modal3", "close")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal("modal3", "close")}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <h5 className="text-center">Upload</h5>
          <div className="progress uploadProgess mt-3 mb-4 d-none">
            <div
              className="progress-bar progress-bar-striped active"
              role="progressbar"
              style={{ width: `${completedPerc}%` }}
              aria-valuenow={completedPerc}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          {!isUploading && (
            <p>
              {file.length} file{file.length === 1 ? "" : "s"} uploaded out of{" "}
              {file.length}
            </p>
          )}
          {isUploading && (
            <div>
              <p className="text-center">Uploading Resumes</p>
              <div className="d-flex justify-content-center mt-4">
                <div style={{ width: 150, height: 150, textAlign: "center" }}>
                  <CircularProgressbar
                    value={completedPerc}
                    text={`${completedPerc}%`}
                    styles={buildStyles({
                      pathColor: "#437AF8",
                      backgroundColor: "#437AF8",
                      textColor: "#437AF8",
                    })}
                  />
                </div>
              </div>
            </div>
          )}
          {notSupportedFiles.length > 0 && (
            <div className="d-flex justify-content-end w-100 mb-3 cursorP">
              {copied ? (
                <>
                  <span className="lightGrey">Copied</span>
                  <IoIosDoneAll color="green" size={25} />
                </>
              ) : (
                <FaCopy
                  size={25}
                  onClick={() => {
                    handleCopy();
                  }}
                />
              )}
            </div>
          )}
          <div
            className={`file-upload-progess-list ${
              !notSupportedFiles.length ? "d-none" : ""
            }`}
          >
            {notSupportedFiles.length > 0 &&
              notSupportedFiles.map((item, index) => {
                return (
                  <p
                    className="danger bodyM mb-1"
                    key={item.name}
                    style={{
                      wordBreak: "break-all",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                    }}
                  >
                    {index + 1 + ": "}
                    {item.name}{" "}
                    <span className="lightGrey">({item.error || null})</span>
                  </p>
                );
              })}
          </div>
          <p
            className="bigCTAprimary mt-2"
            onClick={() => {
              if (isProcessing) return;
              toggleModal("modal3", "close");
              setProgessBar(0);
              if (!notSupportedFiles.length) {
                toggleModal("modal4", "open");
              }
            }}
          >
            {isProcessing ? "Processing" : "Ok"}
          </p>
        </Modal.Body>
      </Modal>
      <Modal
        show={show.modal4}
        onHide={() => toggleModal("modal4", "close")}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal("modal4", "close")}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <img src={GreenTick} alt="" />
          <h6 className="text-center mt-3">
            {file.length} resume{file.length === 1 ? "" : "s"} uploaded
            successfully
          </h6>
          <p>
            Our AI engine is matching the resumes. Please have a coffee and come
            back after a while to see the ranked resumes on the Resumes tab.
          </p>
          <p
            className="bigCTAprimary mt-2"
            onClick={() => toggleModal("modal4", "close")}
          >
            Ok
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal5}
        onHide={() => {
          setSelectedResume(null);
          toggleModal("modal5", "close");
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="resume-page"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal("modal5", "close")}
          ></button>
        </div>
        <Modal.Body className="text-center">
          <FileViewer bucketKey={selectedResume?.bucket_key} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default JobDescription;
