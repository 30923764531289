import axios from 'axios';

const api = axios.create({
  baseURL: 'https://backend.fairee.com.sg/',
  withCredentials: true,
});

export default api;

// baseURL: 'https://fairee-backend-xj8lb.ondigitalocean.app/',
// https://backend.fairee.com.sg/
