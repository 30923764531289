import './job-position.css';
import ArrowBack from '../../../../assets/images/arrow-narrow-back.svg';
import { Form } from 'react-bootstrap';
import SetDesiredPersonality from './set-desired-personality';
import { useEffect, useState } from 'react';
import { fetchAllowedFileTypes } from '../../../services/job-services';

const AddJobPosition = () => {
  const types = [
    'Fulltime',
    'Parttime',
    'Contract',
    'Temporary',
    'Volunteer',
    'Internship',
    'Other',
    'Fulltime (Client)',
    'Parttime (Client)',
    'Contract (Client)',
    'Temporary (Client)',
    'Volunteer (Client)',
    'Internship (Client)',
    'Other (Client)',
  ];

  const [formData, setFormData] = useState({
    company_job_id: '',
    department: '',
    title: '',
    job_type: '',
    location: '',
    job_description: '',
    client_name: '',
    industry_name: '',
  });

  const updateForm = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  const [isPersonality, setIsPersonality] = useState(false);

  const [validated, setValidated] = useState(false);

  const addJob = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (file.length === 0 && !formData.job_description)
      setFileError({ isError: true, message: 'Please Upload file' });
    else setFileError({ isError: false });
    if (
      form.checkValidity() === false ||
      fileError.isError ||
      (file.length === 0 && !formData.job_description)
    ) {
      setValidated(true);
    } else {
      console.log(formData);
      setIsPersonality(true);
    }
  };

  const [fileTypes, setFileTypes] = useState({});
  const getAllowedFilesTypes = async () => {
    try {
      const types = await fetchAllowedFileTypes();
      setFileTypes(types.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllowedFilesTypes();
    updateForm('job_type', types[0]);
  }, []);

  const [fileError, setFileError] = useState({ isError: false, message: '' });
  const [file, setFile] = useState([]);
  const checkFile = (file, type) => {
    if (
      Array(file.target.files).length > 0 &&
      fileTypes &&
      fileTypes.job_description.filter_mimetype.includes(
        file.target.files[0].type
      )
    ) {
      setFile(file.target.files);
      updateForm('job_description', file.target.files[0]);
      setFileError({ isError: false });
    } else setFileError({ isError: true, message: 'File type not supported.' });
  };

  const blobConversion = (e) => {
    if (e.target.value !== '') {
      const value = e.target.value;
      const blob = new Blob([value], {
        type: 'text/plain;charset=utf-8',
      });
      const file = new File([blob], 'job-description.txt', {
        type: 'text/plain',
      });
      updateForm('job_description', file);
    }
  };

  return (
    <>
      <div className="container my-5">
        {!isPersonality ? (
          <Form
            className="formSet"
            noValidate
            validated={validated}
            onSubmit={addJob}
          >
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Job ID</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Job ID"
                        value={
                          Object.keys(formData).length > 0 &&
                          formData.company_job_id
                        }
                        onChange={(e) =>
                          updateForm('company_job_id', e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Job Type Select</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={
                          Object.keys(formData).length > 0 && formData.job_type
                        }
                        onChange={(e) => updateForm('job_type', e.target.value)}
                        defaultValue={types[0]}
                      >
                        {types.map((item) => {
                          return (
                            <option value={item} key={item}>
                              <span
                                style={{
                                  textTransform: 'capitalize',
                                }}
                              >
                                {item}
                              </span>
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Department"
                        value={
                          Object.keys(formData).length > 0 &&
                          formData.department
                        }
                        onChange={(e) =>
                          updateForm('department', e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Job Position</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Job Position"
                        value={
                          Object.keys(formData).length > 0 && formData.title
                        }
                        onChange={(e) => updateForm('title', e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Location"
                        value={
                          Object.keys(formData).length > 0 && formData.location
                        }
                        onChange={(e) => updateForm('location', e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                {formData.job_type.includes('Client') ? (
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Client Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Client Name"
                          value={
                            Object.keys(formData).length > 0 &&
                            formData.client_name
                          }
                          onChange={(e) =>
                            updateForm('client_name', e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Industry Sector</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Industry Sector"
                          value={
                            Object.keys(formData).length > 0 &&
                            formData.industry_name
                          }
                          onChange={(e) =>
                            updateForm('industry_name', e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Attach Job Description File</Form.Label>
                      <input
                        type="file"
                        placeholder="Choose File"
                        accept={
                          Object.keys(fileTypes).length > 0 &&
                          fileTypes.job_description.filter_mimetype.toString()
                        }
                        onChange={(e) => checkFile(e, 'fileUpload')}
                      />
                      <p className="mt-2">
                        Attach
                        {Object.values(fileTypes).length > 0 &&
                          fileTypes.job_description.filter_extension.map(
                            (item) => {
                              return ' ' + item;
                            }
                          )}
                      </p>
                      {fileError.isError ? (
                        <div className="error-toaster">{fileError.message}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <div className="p-2 custom-border">
                      <p className="m-0 mb-1 fw-semibold">
                        Please ensure that the Job Description includes:
                      </p>
                      <ul className="m-0">
                        <li>Minimum years of experience</li>
                        <li>Minimum educational qualification </li>
                        <li>Required skill sets</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>OR Type below</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="textarea"
                        onChange={(e) => blobConversion(e)}
                        disabled={file.length > 0}
                      />
                    </Form.Group>
                  </div>
                </div>
                <button
                  type="submit"
                  className="smallCTAprimary mt-4 desiredBtn"
                >
                  Set Desired Personality
                </button>
              </div>
            </div>
          </Form>
        ) : (
          <>
            <div className="d-flex">
              <img
                src={ArrowBack}
                alt="back"
                onClick={() => setIsPersonality(false)}
                width="23"
              ></img>
              <span
                onClick={() => setIsPersonality(false)}
                className="cursorP smallBold ml-0"
                style={{ fontSize: '17px' }}
              >
                Go Back
              </span>
            </div>
            <SetDesiredPersonality
              filledData={formData}
            ></SetDesiredPersonality>
          </>
        )}
      </div>
    </>
  );
};

export default AddJobPosition;
