import React from 'react';

const Stats = ({
  totalNumberOfResumes,
  totalOpenPosition,
  totalVideoSubmission,
}) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="features mVgap">
          <img src="images/suitcase-big_blue.svg" alt="" />
          <div>
            Total Open Job Positions
            <span>{totalOpenPosition}</span>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="features mVgap">
          <img src="images/docBlue_icon.svg" alt="" />
          <div>
            Total Number of Resumes
            <span>{totalNumberOfResumes}</span>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="features mVgap">
          <img src="images/videoBlue_icon.svg" alt="" />
          <div>
            Total Video Submissions
            <span>{totalVideoSubmission}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
