import "./header.css";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../../../../assets/images/logo_fairee.png";

const Header = () => {
  const location = useLocation();
  const isVideoUploadPage = location.pathname.includes("/video_upload");
  console.log("isVideoUploadPage", isVideoUploadPage);
  return (
    <nav className="navbar navbar-expand-md fixed-top">
      <div className="container">
        <a
          className="navbar-brand"
          href="https://www.fairee.com.sg"
          target="_blank"
        >
          <img src={LOGO} alt="logo" />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src="images/hamburger.svg" alt="" />
        </button>
        {isVideoUploadPage ? null : (
          <div className="navbar-collapse collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto flex-nowrap">
              <li className="nav-item">
                <NavLink aria-current="page" className="nav-link d-none" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link d-none" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link d-none" to="/price">
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link d-none" to="support">
                  Support
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link smallCTAsecondary"
                  to="/company-registration"
                >
                  New Company Registration
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link smallCTAprimary active"
                  to="/login"
                >
                  Log In
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
