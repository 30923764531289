import api from 'config/backend-url';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import GreenTick from '../../../assets/images/green-tick.svg';

const Security = () => {
  const [inputData, setInputData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const onChange = ({ target: { name, value } }) => {
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setInputData({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setIsSubmitting(false);
  };

  const onSubmit = async () => {
    const { confirmNewPassword, newPassword, currentPassword } = inputData;

    if (confirmNewPassword !== newPassword) {
      toastr.error('Error', 'Passwords do not match', {
        attention: true,
      });
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await api.post('/user/change_password', {
        old_password: currentPassword,
        new_password: newPassword,
      });
      const { data } = response;

      if (data.response_code === -1) {
        toastr.error('Error', 'Something went wrong', {
          attention: true,
        });
        return;
      }
      setIsShowModal(true);
      clearState();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isDisabled =
    inputData.currentPassword === '' ||
    inputData.newPassword === '' ||
    inputData.confirmNewPassword === '' ||
    isSubmitting;

  return (
    <>
      <div
        className="tab-pane fade"
        id="nav-security"
        role="tabpanel"
        aria-labelledby="nav-security-tab"
        tabindex="0"
      >
        <div className="row">
          <div className="col-md-4">
            <form
              action=""
              className="formSet mt-4"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label htmlFor="">Current Password</label>
              <input
                type="password"
                value={inputData.currentPassword}
                name="currentPassword"
                onChange={onChange}
                placeholder="*********"
              />
              <label htmlFor="">New Password</label>
              <input
                type="password"
                value={inputData.newPassword}
                name="newPassword"
                onChange={onChange}
                placeholder="*********"
              />
              <label htmlFor="">Confirm New Password</label>
              <input
                type="password"
                value={inputData.confirmNewPassword}
                name="confirmNewPassword"
                onChange={onChange}
                placeholder="*********"
              />
              <button
                className={`bigCTAprimary w-100 mt-3 ${
                  isDisabled ? 'disables' : ''
                }`}
                onClick={onSubmit}
              >
                {isSubmitting ? 'Submitting...' : 'Save'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={isShowModal}
        onHide={() => {
          setIsShowModal(false);
        }}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setIsShowModal(false);
            }}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: 'center' }}>
          <img src={GreenTick} alt="" />
          <p className="bodyM mt-2">Password changed successfully</p>
          <div className="d-flex justify-content-center">
            <p
              className="smallCTAprimary w-50 cursorP"
              onClick={() => {
                setIsShowModal(false);
              }}
            >
              Ok
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Security;
