import * as actions from './action-type';


export const onLogin = (data) => ({
    type: actions.ON_LOGIN,
    payload: {
        data
    }
})

export const onLoggout = () => ({
    type: actions.ON_LOGGOUT
})

export const onNavigate = (data) => ({
    type: actions.ON_NAVIGATE,
    payload: {
        data
    }
})