import { useQuery } from '@tanstack/react-query';

import { getProfileDetails } from 'container/services/auth-services';

const TIME = 10 * 60 * 1000; // 10 minutes

export const useGetProfileDetails = () =>
  useQuery({
    queryKey: ['profile_details'],
    queryFn: getProfileDetails,
    refetchInterval: TIME,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: TIME,
    gcTime: TIME,
  });
