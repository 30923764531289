import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { onNavigate } from '../../../store/action';

const JobPositionList = (props) => {
  const [jobs, setJobs] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const jobs = props.jobs.filter(
      (job) =>
        job.status ===
        (props.status.open
          ? 'ACTIVE'
          : props.status.closed
          ? 'CLOSED'
          : 'ARCHIVED')
    );
    jobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setJobs([...jobs]);
  }, [props]);

  const filteredJobs = jobs.filter((job) => {
    if (!searchText) return true;

    return (
      job.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.department?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.client_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.industry_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.location?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.created_by?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      job.company_job_id?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-3 align-items-center">
          <div className="col-md-5 cardControls mb-0 ">
            <h5>
              {jobs.length + ' '}
              {props.status.open
                ? 'Open'
                : props.status.closed
                ? 'Closed'
                : props.status.deleted
                ? 'Deleted'
                : ''}{' '}
              Job Positions
            </h5>
          </div>
          <div className="col-md-7 cardControls mb-0">
            <div className="controlls">
              <div className="dropdown d-none">
                Sort by:{' '}
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Creation Date
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 2
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 3
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dropdown d-none">
                User:{' '}
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  All Users
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 1
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 2
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Link 3
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <form action="" className="formSet">
                  <input
                    type="search"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          {filteredJobs.length > 0 &&
            filteredJobs.map((job) => {
              return (
                <div className="col-md-4 mb-3" key={job.id}>
                  <div className="jobCards mVgap">
                    <div className="department m-3 smallSubT">
                      {job.client_name
                        ? `${job.client_name} (${job.industry_name || ''})`
                        : job.department}
                      <a href="#" style={{ cursor: 'default' }}>
                        ID - {job.company_job_id}
                      </a>
                    </div>
                    <h5 className="m-3" style={{ textTransform: 'capitalize' }}>
                      {job.title}
                    </h5>
                    <div className="department jNumb bgLight bRadius8 m-3">
                      <div className="jobNumbers">
                        {(job.resume_count || 0) + (job.video_count || 0)}{' '}
                        <span>Total</span>
                      </div>
                      <div className="vLine">&nbsp;</div>
                      <div className="jobNumbers">
                        {job.video_count}{' '}
                        <span>Shortlisted for Video introduction</span>
                      </div>
                    </div>
                    <div className="department jobPosition m-3">
                      <span>
                        {job.location || 'Location'} • {job.job_type}
                      </span>
                      <span>
                        User: <strong>{job.created_by.name}</strong>
                      </span>
                    </div>
                    <div className="cardFoot">
                      <NavLink
                        to={`/job-details/${job.id}`}
                        onClick={() => {
                          props.onNavigate({
                            pageHeading: job.title,
                            subHeading: job.client_name
                              ? `${job.client_name} (${job.industry_name})`
                              : job.department,
                          });
                        }}
                        className="tertiaryBtn"
                      >
                        View Details
                      </NavLink>
                    </div>
                  </div>
                </div>
              );
            })}
          {jobs.length === 0 && <p>No Data</p>}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNavigate: (data) => dispatch(onNavigate(data)),
  };
};

export default connect('', mapDispatchToProps)(JobPositionList);
