import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from "redux-thunk";
import { reducer as toastrReducer } from "react-redux-toastr";
import reducer from "./reducer";

const combineReducer = combineReducers({
    toastr: toastrReducer,
    reducer
})

const middleware = [thunk]

const store = createStore(
    combineReducer,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store