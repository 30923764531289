import React from "react";
import Billing from "./Billing";
import UserManagement from "./UserManagement";
import CompanyProfile from "./CompanyProfile";
import Security from "./Security";
import MyProfile from "./MyProfile";

import { Spinner } from "react-bootstrap";
import { useGetProfileDetails } from "hooks/useGetProfileDetails";

const tabs = [
  {
    label: "My Profile",
    id: "profile",
    active: true,
  },
  {
    label: "Security",
    id: "security",
    active: false,
  },
  {
    label: "Company Profile",
    id: "c-profile",
    active: false,
  },
  {
    label: "User Management",
    id: "management",
    active: false,
    isForAdmin: true,
  },
  {
    label: "Billing",
    id: "billing",
    active: false,
    isForAdmin: true,
  },
];

const ProfilePage = () => {
  const { isLoading, data, refetch } = useGetProfileDetails();
  if (isLoading) {
    <Spinner animation="border" role="status" variant="primary" />;
  }

  const userData = data?.data;

  if (!userData) {
    return <div>...</div>;
  }

  const isAdmin = userData?.is_user_admin || false;

  return (
    <div className="container-fluid mt-4">
      {/* Tabs */}
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {tabs
          .filter((tab) => {
            if (isAdmin) {
              return true;
            }
            return tab.isForAdmin !== true;
          })
          .map((tab) => (
            <button
              key={tab.id}
              className={`nav-link ${tab.active ? "active" : ""}`}
              id={`nav-${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#nav-${tab.id}`}
              type="button"
              role="tab"
              aria-controls={`nav-${tab.id}`}
              aria-selected={tab.active}
              onClick={refetch}
            >
              {tab.label}
            </button>
          ))}
      </div>

      {/* Tab Content */}
      <div className="tab-content" id="nav-tabContent">
        <MyProfile userData={userData?.my_profile} />
        <Security />
        <CompanyProfile
          companyData={userData?.company_profile}
          isAdmin={isAdmin}
        />
        <UserManagement userManagementData={userData?.user_management || []} />
        <Billing />
      </div>
    </div>
  );
};

export default ProfilePage;
