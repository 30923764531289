import React from 'react';

// import './interhire.css';

const InternalHire = () => {
  return (
    <main className="c6 doc-content text-left">
      <p className="c2">
        <span className="c0 c4">From : support</span>
        <span className="c0 c5 c4">@fairee.com.sg</span>
      </p>
      <p className="c2">
        <span className="c0 c5 c4">To : &lt;&lt;Applicant Email&gt;&gt;</span>
      </p>
      <p className="c2">
        <span className="c0 c5 c4">Cc : &lt;&lt;user email&gt;&gt;</span>
      </p>
      <p className="c2 c8">
        <span className="c0 c5 c4"></span>
      </p>
      <p className="c2">
        <span className="c0 c5 c4">
          Email Subject: Your application for the position of &lt;&lt;Job
          Position&gt;&gt; at &lt;&lt;Company Name&gt;&gt;
        </span>
      </p>
      <p className="c3">
        <span className="c0 c1">Dear &lt;&lt;</span>
        <span className="c0 c1">Applicant Name&gt;&gt;</span>
        <span className="c0 c5 c1">,</span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          You are receiving this email via Fairee Technologies Pte Ltd on behalf
          of &lt;&lt;Company Name&gt;&gt;
        </span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          Thank you for submitting your resume for the position of:
        </span>
      </p>
      <p className="c3">
        <span className="c0 c1">Job Position : &lt;&lt;J</span>
        <span className="c0 c4">ob Position</span>
        <span className="c0 c5 c1">&gt;&gt;</span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          Company Name : &lt;&lt;Company Name&gt;&gt;
        </span>
      </p>
      <p className="c3">
        <span className="c0 c1">
          We have reviewed your resume and have shortlisted you for the next
          stage of our recruitment process. You are requested to upload a short{' '}
        </span>
        <span className="c0">4-5</span>
        <span className="c0 c5 c1">
          &nbsp;minute self-introduction video for us to know you better.
        </span>
      </p>
      <p className="c3">
        <span className="c0 c1">
          Please note that the video introduction does not replace human
          intervention with any automated decisions. Shortlist review and
          selection decisions of the candidate video will be made by the hiring
          manager and/or the recruiter.
        </span>
        <span className="c0 c5 c1">&nbsp;</span>
      </p>
      <p className="c3">
        <span className="c0 c1 c5">
          Kindly introduce yourself and answer the following question in your
          video :
        </span>
      </p>
      <p className="c3">
        <span className="c1 c10">
          Q. In what ways do you believe that your professional background and
          skill set align with the requirements of the position you have applied
          for?
        </span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          Please record your video with clear audio and in a well-lit
          environment (with your front face clearly visible), on your desktop,
          laptop or mobile/tablet, and follow the instructions as mentioned
          below to upload the video by the submission deadline. Only the videos
          sent via the upload link will be accepted. The upload site is managed
          by our recruitment services partner, Fairee Technologies Pte Ltd.
        </span>
      </p>
      <p className="c3">
        <span className="c0 c1">Video Upload Link : </span>
        <span className="c0 c4">&lt;&lt;Personalized Video Link&gt;&gt; </span>
      </p>
      <p className="c3">
        <span className="c0 c1">Submission Deadline : </span>
        <span className="c0 c4">&lt;&lt;Date </span>
        <span className="c0 c4">(5 calendar days)&gt;&gt;</span>
      </p>
      <p className="c3">
        <span className="c0 c1">Video Duration : </span>
        <span className="c0 c1">4-5</span>
        <span className="c0 c5 c1">&nbsp;minutes</span>
      </p>
      <p className="c3">
        <span className="c0 c1">Video Size Limit : </span>
        <span className="c0">150</span>
        <span className="c0 c5 c1">&nbsp;MB</span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          We wish you all the best for the next stage of this journey. We will
          contact you if you are selected for the subsequent stages of this
          recruitment process.
        </span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">
          Please do not hesitate to contact us by replying to this email only if
          you face any technical difficulties in uploading your video.
        </span>
      </p>
      <p className="c3">
        <span className="c0 c5 c1">Best regards,</span>
      </p>
      <p className="c2">
        <span className="c0 c5 c4">&lt;&lt;Name of User&gt;&gt;</span>
      </p>
      <p className="c2">
        <span className="c0 c4">&lt;&lt;Company Name&gt;&gt;</span>
      </p>
      <p className="c3">
        <span className="c0 c1">
          Note: This is a system generated email. Inadvertent errors and
          omissions due to system issues are regretted. If you are not the
          intended recipient of this email, please let us know at{' '}
        </span>
        <span className="c0 c4">support@fairee.com.sg.</span>
      </p>
      <p className="c3 c8">
        <span className="c0 c5 c1"></span>
      </p>
      <p className="c2 c8">
        <span className="c0 c5 c7"></span>
      </p>
    </main>
  );
};

export default InternalHire;
