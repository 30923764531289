import '../form-style.css';
import './company-registration.css';
import ImageCover from '../../../../assets/images/company-registration-cover.png';
import QuestionMark from '../../../../assets/images/question-mark-icon.svg';
import GreenTick from '../../../../assets/images/green-tick.svg';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  emailChecker,
  passwordChecker,
  phoneNumberChecker,
} from '../../../shared/helper';
import {
  companyRegistration,
  fetchCompanyRegistrationOtp,
} from '../../../services/auth-services';
import countryCodes from 'country-codes-list';
import TermsCondition from '../../../shared/terms-condition';
import PrivacyPolicy from '../../../shared/privacy-policy';
import { toastr } from 'react-redux-toastr';

const CompanyRegistration = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
  });

  const handleClose = (type) =>
    setShow((prev) => ({
      ...prev,
      modal1: type === 'modal1' ? false : show.modal1,
      modal2: type === 'modal2' ? false : show.modal2,
      modal3: type === 'modal3' ? false : show.modal3,
    }));

  const handleShow = (type) => {
    setShow((prev) => ({
      ...prev,
      modal1: type === 'modal1' ? true : show.modal1,
      modal2: type === 'modal2' ? true : show.modal2,
      modal3: type === 'modal3' ? true : show.modal3,
    }));
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});

  const [submitted, setSubmitted] = useState(false);

  const [emailError, setEmailError] = useState([]);
  const [otpError, setOtpError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    coutnry: '',
    code: '',
    countryCode: '',
  });
  const [togglePrivacyPolicy, setTogglePrivacyPolicy] = useState(false);

  useEffect(() => {
    let tempObject = countryCodes.customList(
      'countryNameEn',
      '{countryCallingCode}:{countryCode}'
    );
    let tempArray = [];
    for (const key in tempObject) {
      if (key === 'India')
        setSelectedCountry({
          country: key,
          code: tempObject[key].split(':')[0],
          countryCode: tempObject[key].split(':')[1],
        });
      tempArray.push({
        country: key,
        code: tempObject[key].split(':')[0],
        countryCode: tempObject[key].split(':')[1],
      });
    }
    tempArray.sort((a, b) => {
      let fa = a.country.toLowerCase(),
        fb = b.country.toLowerCase();
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    });
    setCountryCodeList(tempArray);
  }, []);

  const validateInput = (key, value) => {
    if (key === 'email') {
      setEmailError(emailChecker(value, 'auth'));
    }
    if (key === 'phone_number') {
      setPhoneNumberError(
        phoneNumberChecker(
          '+' + selectedCountry.code + value,
          selectedCountry.countryCode
        )
      );
    }
  };

  const updateForm = (key, value) => {
    validateInput(key, value);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const generateOtp = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    if (
      form.checkValidity() === false ||
      emailError.length > 0 ||
      phoneNumberError.length > 0
    ) {
      setValidated(true);
    } else {
      try {
        const getOtp = await fetchCompanyRegistrationOtp(formData.email);
        if (getOtp) {
          handleShow('modal1');
          updateForm('phone_number_code', '+' + selectedCountry.code);
          setValidated(false);
        }
      } catch (error) {
        toastr.error(error);
        console.log(error);
      }
    }
  };

  const register = async () => {
    try {
      const companyRegister = await companyRegistration(formData);
      console.log('companyRegister', companyRegister);
      if (companyRegister) {
        handleClose('modal1');
        setShow((prevState) => ({
          ...prevState,
          modal2: true,
        }));
      }
    } catch (error) {
      setOtpError(true);
      toastr.error(error);
      console.log(error);
    }
  };

  const redirectToSignUp = () => navigate('/signup');

  const [timer, setTimer] = useState(30);
  useEffect(() => {
    const checkTimer =
      timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(checkTimer);
  }, [timer]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-4 order-md-2">
            <div className="onboardForm">
              <Form
                noValidate
                validated={validated}
                onSubmit={generateOtp}
                className="formSet"
              >
                <h2 className="mb-3">New company registration</h2>
                <Form.Group>
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Full name of the company"
                    onChange={(e) => updateForm('name', e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Point of Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Point of contact name"
                    onChange={(e) => updateForm('poc_name', e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Point of Contact Designation</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Point of Contact Designation"
                    onChange={(e) =>
                      updateForm('poc_designation', e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Point of Contact Official Email Id
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="tooltip-bottom">
                          Please don’t use personal email id
                        </Tooltip>
                      }
                    >
                      <img src={QuestionMark} alt="" className="ml-1" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Point of contact official email id"
                    onChange={(e) => updateForm('email', e.target.value)}
                    className={
                      submitted && emailError.length > 0
                        ? 'form-control-cstm'
                        : null
                    }
                  />
                  {submitted && emailError.length > 0 ? (
                    <div className="error-toaster">{emailError[0].message}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Point of Contact Mobile</Form.Label>
                  <div className="phoneCodeWrapper">
                    <Form.Control
                      type="text"
                      required
                      placeholder="Point of Contact Mobile"
                      onChange={(e) =>
                        updateForm('phone_number', e.target.value)
                      }
                      className={
                        submitted && phoneNumberError.length > 0
                          ? 'form-control-cstm cstmPaddingLeft'
                          : 'cstmPaddingLeft'
                      }
                    />
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        {'+' + selectedCountry.code}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {countryCodeList.length > 0 &&
                          countryCodeList.map((item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                eventKey={item.code}
                                onClick={() =>
                                  setSelectedCountry({
                                    country: item.country,
                                    code: item.code,
                                    countryCode: item.countryCode,
                                  })
                                }
                                className={
                                  item.code === selectedCountry.code
                                    ? 'selectedDropdown'
                                    : ''
                                }
                              >
                                {item.country}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {submitted && phoneNumberError.length > 0 ? (
                    <div className="error-toaster">
                      {phoneNumberError[0].message}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="formBasicCheckbox"
                  style={{ display: 'flex' }}
                  className="mt-3 bodyM"
                >
                  <Form.Check
                    type="checkbox"
                    id="tandc"
                    name="tandc"
                    required
                  />
                  <Form.Label style={{ margin: 0, marginLeft: 5 }}>
                    I Agree to the
                    <a
                      href="#"
                      onClick={() => {
                        handleShow('modal3');
                        setTogglePrivacyPolicy(false);
                      }}
                    >
                      {' '}
                      Terms & Conditions
                    </a>{' '}
                    and
                    <a
                      href="#"
                      onClick={() => {
                        handleShow('modal3');
                        setTogglePrivacyPolicy(true);
                      }}
                    >
                      {' '}
                      Privacy Policy
                    </a>
                  </Form.Label>
                </Form.Group>
                <button type="submit" className="bigCTAprimary w-100 mt-3">
                  Register Now
                </button>
              </Form>
            </div>
          </div>
          <div className="col-md-7 order-md-1">
            <div className="onboardCover">
              <img src={ImageCover} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show.modal1}
        onHide={() => handleClose('modal1')}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose('modal1')}
          ></button>
        </div>
        <Modal.Body>
          <h3>Verify OTP</h3>
          <form action="" className="formSet">
            <label htmlFor="">
              Please enter the OTP sent to your official email id (Check the
              spam folder if you didn't receive the OTP in your inbox)
            </label>
            <input
              type="text"
              placeholder="OTP"
              onChange={(e) => updateForm('otp', e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {timer === 0 ? (
                <span
                  className="bodyM cursorP"
                  onClick={() => fetchCompanyRegistrationOtp(formData.email)}
                >
                  Resend OTP
                </span>
              ) : (
                <span className="bodyM">
                  Resend OTP in <span className="sucessfull">{timer}s</span>
                </span>
              )}
              {otpError ? <span className="danger">Invalid OTP</span> : <></>}
            </div>
            <button
              type="button"
              className="bigCTAprimary w-100 mt-3"
              onClick={register}
            >
              Verify
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal2}
        onHide={() => handleClose('modal2')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose('modal2')}
          ></button>
        </div>
        <Modal.Body style={{ textAlign: 'center' }}>
          <img src={GreenTick} alt="" />
          <p className="bodyM mt-2">
            You have successfully registered your company. Your Company Access
            Key has been sent to your official email id (
            <span className="actives">{formData.email}</span>).
          </p>
          <Button onClick={redirectToSignUp}>Sign up as a user</Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show.modal3}
        onHide={() => handleClose('modal3')}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="cstm"
        scrollable="true"
      >
        <div className="w-100 btn-close-flex">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose('modal3')}
          ></button>
        </div>
        <Modal.Body>
          {togglePrivacyPolicy ? (
            <PrivacyPolicy
              onClose={() => handleClose('modal3')}
            ></PrivacyPolicy>
          ) : (
            <TermsCondition
              onClose={() => handleClose('modal3')}
            ></TermsCondition>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompanyRegistration;
